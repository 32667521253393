const convertToISOString = (dateString) => {
  const monthMap = {
    January: "01",
    February: "02",
    March: "03",
    April: "04",
    May: "05",
    June: "06",
    July: "07",
    August: "08",
    September: "09",
    October: "10",
    November: "11",
    December: "12",
  };

  const dateParts = dateString.split(" ");

  const day = dateParts[0].slice(0, -2);
  const monthName = dateParts[1];
  const month = monthMap[monthName];
  const year = dateParts[2].slice(0, -1);

  const time = dateParts.length > 3 ? dateParts[3] : "00:00";

  const convertedDate = `${year}-${month}-${day}T${time}:00.000+0000`;

  return convertedDate;
};

export default convertToISOString;
