import React, { useEffect, useState, useLayoutEffect } from "react";
import {
  FormControl,
  Select,
  TextField,
  Chip,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Alert,
  TableFooter,
  Pagination,
  Tooltip,
} from "@mui/material";
import { DateTimeRangePicker } from "react-datetime-range-super-picker";
import "react-datetime-range-super-picker/dist/index.css";
import { DNA } from "react-loader-spinner";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";

import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import data from "../../utils/TransactionsMap";
import TransactionDetails from "../../components/Elements/Transactions/TransactionDetails";
import {
  URL,
  MERCHANT_ID,
  PAYMENT_TRANSACTIONS_ENDPOINT,
} from "constants/Constants";
import convertToISOString from "utils/ConvertDateToString";
import "./Transactions.css";
import paymentTypeIcons from "utils/PaymentTypeIcons";
import processorIcons from "utils/ProcessorIcons";
import { httpClient } from "utils/HttpClient";
import { StyledTableCell, StyledTableRow } from "utils/Styles";
import Logout from "Logout";

function Transactions() {
  const [transactions, setTransactions] = useState([]);
  const [tableHeadings, setTableHeadings] = useState([]);
  let initialState = {};
  const [from_date, setFromDate] = useState();
  const [to_date, setToDate] = useState();
  const [filters, setFilters] = useState(initialState);
  const [chipDelete, setChipDelete] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [warning, setWarning] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [clickedChipIndex, setClickedChipIndex] = useState(null);
  const allColumns = Object.keys(data.transactionsMap);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedOptionsMap, setSelectedOptionsMap] = useState({});
  const [checkedColumns, setCheckedColumns] = useState(
    allColumns.filter((columnKey) =>
      data.ListOfShowingColumns.includes(columnKey)
    )
  );
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [clickedLinkId, setClickedLinkId] = useState();
  const [selectedTransactionType, setSelectedTransactionType] = useState();
  const handleLinkClick = (id, type) => {
    console.log(type);
    setSelectedTransactionType(type);
    console.log(selectedTransactionType);
    setClickedLinkId(id);
  };

  const handleDetailsClose = () => {
    console.log("close");
    setClickedLinkId(null);
    setSelectedTransactionType(null);
  };

  const handleFromDateUpdate = ({ date }) => {
    console.log(date);
    setFromDate(date.date);
  };
  const handleToDateUpdate = ({ date }) => {
    setToDate(date.date);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(1);
  //   fetchTransactionDataWithFilter();
  // };

  const hasFilters = (filter) =>
    filters[filter.filterOptions.queryParam]?.length ||
    (filters[filter.filterOptions.queryParamInbound]?.length &&
      filters[filter.filterOptions.queryParamOutbound]?.length);

  Object.keys(data.transactionsMap).forEach((key) => {
    const selectedOptions =
      data.transactionsMap[key].filterOptions.selectedOptions;
    initialState = {
      ...initialState,
      [key]: selectedOptions,
    };
  });
  const renderTableCell = (item, columnKey, data) => {
    const { transactionsMap } = data;
    const columnData = transactionsMap[columnKey];
    const path = columnData.path;

    if (path === "paymentMethod" || path === "processorCode") {
      const value = getFirstNonNullValue(item, path);
      return (
        <Tooltip key={value} title={value}>
          {value && (
            <img
              style={{ height: 48, width: 48 }}
              src={paymentTypeIcons[value] || processorIcons[value] || null}
            />
          )}
        </Tooltip>
      );
    } else if (path === "merchantReferenceId") {
      return (
        <a
          href="#"
          onClick={() => handleLinkClick(item.id, item.transactionType)}
        >
          {item.merchantReferenceId}
        </a>
      );
    } else {
      return getFirstNonNullValue(item, path);
    }
  };

  useEffect(() => {
    fetchTransactionDataWithFilter();
  }, [chipDelete, page]);
  useLayoutEffect(() => {
    fetchTransactionData();
  }, []);
  const fetchTransactionData = () => {
    setPage(1);
    const formData = new URLSearchParams();
    formData.append(
      "transactionType",
      "PAYMENT, CAPTURE, REFUND, CREDIT, CHECKOUT_SESSION"
    );

    const response = httpClient
      .postFormData(
        `${URL}${PAYMENT_TRANSACTIONS_ENDPOINT}?merchant_id=${MERCHANT_ID}&type=transactions&orderbycol=transactionTime&orderby=DESC&pageNumber=${page}&pageSize=${rowsPerPage}`,
        formData
      )
      .then((response) => {
        // Check if the status code is 200
        if (response.ok) {
          return response.json();
        } else {
          // Check if the status code is 401
          if (response.status === 401) {
            Logout();
          } else {
            setWarning(true);
          }
        }
      })
      .then((responseData) => {
        console.log(responseData);
        setTransactions(responseData);
        if (responseData.length > 0) {
          // Assuming the first item in the response array contains all table headings
          setTableHeadings(Object.keys(responseData[0]));
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors here
      });
  };

  const fetchTransactionDataWithFilter = (pageNumber, pageSize) => {
    console.log("in http call ", filters);
    setIsLoading(true);
    var formData = new URLSearchParams({
      ...Object.fromEntries(
        Object.entries(filters).filter(([key, value]) => {
          return Array.isArray(value)
            ? value.some((item) => item !== null)
            : value !== null;
        })
      ),
    });

    console.log(formData.toString());

    if (formData.toString().length === 0) {
      console.log("formdata", formData);
      console.log("in length = 0");
      formData = new URLSearchParams();
      formData.append(
        "transactionType",
        "PAYMENT, CAPTURE, REFUND, CREDIT, CHECKOUT_SESSION"
      );
    }

    httpClient
      .postFormData(
        `${URL}${PAYMENT_TRANSACTIONS_ENDPOINT}?merchant_id=${MERCHANT_ID}&type=transactions&orderbycol=transactionTime&orderby=DESC&pageNumber=${page}&pageSize=${rowsPerPage}`,
        formData
      )
      .then((response) => {
        // Check if the status code is 200
        if (response.ok) {
          return response.json();
        } else {
          // Check if the status code is 401
          if (response.status === 401) {
            Logout();
          } else {
            setWarning(true);
          }
        }
      })
      .then((responseData) => {
        console.log(responseData);
        setIsLoading(false);
        setTransactions(responseData);
        if (responseData.length > 0) {
          // Assuming the first item in the response array contains all table headings
          setTableHeadings(Object.keys(responseData[0]));
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors here
      });
  };

  const handleFilterChange = (type, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [type]: value,
    }));
  };
  const reloadFilters = () => {
    setWarning(false);

    fetchTransactionDataWithFilter();
  };
  const resetFilters = (filter, queryParamInbound, queryParamOutbound) => {
    setChipDelete((prevValue) => !prevValue);

    //console.log("filters ", filters);
    if (filter) {
      // Reset specific filter
      setFilters((prevFilters) => ({
        ...prevFilters,
        [filter]: Array.isArray(filter)
          ? []
          : typeof filter === "string"
          ? null
          : { [queryParamInbound]: null, [queryParamOutbound]: null },
      }));
      if (Array.isArray(filters[filter])) {
        setSelectedOptionsMap((prevSelectedOptionsMap) => ({
          ...prevSelectedOptionsMap,
          [filter]: [],
        }));
      }
      console.log("filters ", filters);
    } else {
      // Reset all filters
      setPage(1);
      setFilters({});
      applyfilters();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setClickedChipIndex(null);
  };
  const applyfilters = () => {
    console.log("apply");

    fetchTransactionDataWithFilter();
    handleClose();
  };
  const handleChipDelete = (
    queryParam,
    queryParamInbound,
    queryParamOutbound
  ) => {
    resetFilters(queryParam, queryParamInbound, queryParamOutbound);

    handleClose();
  };

  const handleCheckboxChange = (columnKey) => (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setCheckedColumns([...checkedColumns, columnKey]);
      if (!data.ListOfShowingColumns.includes(columnKey)) {
        data.ListOfShowingColumns.push(columnKey);
      }
      data.ListOfShowingColumns.sort(
        (a, b) => data.transactionsMap[a].order - data.transactionsMap[b].order
      );
    } else {
      setCheckedColumns(checkedColumns.filter((col) => col !== columnKey));
      data.ListOfShowingColumns = data.ListOfShowingColumns.filter(
        (col) => col !== columnKey
      );
      data.ListOfShowingColumns.sort(
        (a, b) => data.transactionsMap[a].order - data.transactionsMap[b].order
      );
    }
  };

  const handleFilterCheckboxChange = (filterParam, option) => {
    setSelectedOptionsMap((prevSelectedOptionsMap) => {
      const selectedOptions = prevSelectedOptionsMap[filterParam] || [];
      const updatedOptions = selectedOptions.includes(option)
        ? selectedOptions.filter((opt) => opt !== option)
        : [...selectedOptions, option];
      handleFilterChange(filterParam, updatedOptions);
      return {
        ...prevSelectedOptionsMap,
        [filterParam]: updatedOptions,
      };
    });
  };

  return (
    <div className="payment-transaction-container">
      {!clickedLinkId ? (
        <>
          <div className="filter-container">
            {Object.values(data.transactionsMap).map(
              (filter, index) =>
                filter.filtered && (
                  <MenuItem
                    key={filter.displayName}
                    style={{ display: "inline-block" }}
                  >
                    <Chip
                      onClick={(event) =>
                        setAnchorEl(
                          { index, currentTarget: event.currentTarget },
                          setClickedChipIndex(index),
                          setSearchTerm("")
                        )
                      }
                      onDelete={
                        hasFilters
                          ? () =>
                              handleChipDelete(
                                filter.filterOptions.queryParam,
                                filter.filterOptions.queryParamInbound,
                                filter.filterOptions.queryParamOutbound
                              )
                          : null
                      }
                      variant="outlined"
                      label={`${filter.displayName} ${
                        filters[filter.filterOptions.queryParam]?.length &&
                        filter.filterOptions.options
                          ? `: ${
                              filters[filter.filterOptions.queryParam].length
                            } of ${filter.filterOptions.options.length}`
                          : ""
                      } ${
                        // label for amount filter
                        filter.filterOptions.type === "INTEGER" &&
                        filters.amount_from &&
                        filters.amount_to
                          ? `(${filters.amount_from} - ${filters.amount_to})`
                          : ""
                      } ${
                        // label for date filter
                        filter.filterOptions.type === "DATE" &&
                        filters.transactionTime_from &&
                        filters.transactionTime_to
                          ? `(${filters.transactionTime_from} - ${filters.transactionTime_to})`
                          : ""
                      }`}
                      sx={{
                        backgroundColor: hasFilters(filter)
                          ? "black"
                          : "transparent",
                        color: hasFilters(filter) ? "white" : "black",
                        "& .MuiChip-deleteIcon": {
                          display: hasFilters(filter) ? "inherit" : "none",
                          color: "white",
                        },
                      }}
                    />

                    <Menu
                      anchorEl={
                        anchorEl && anchorEl.index === index
                          ? anchorEl.currentTarget
                          : null
                      }
                      open={Boolean(anchorEl && anchorEl.index === index)}
                      onClose={() => {
                        setAnchorEl(null);
                        setClickedChipIndex(null);
                      }}
                    >
                      {filter.filterOptions.type === "INTEGER" && (
                        <>
                          <MenuItem>
                            <TextField
                              size="small"
                              type="number"
                              label="From"
                              placeholder="From"
                              value={filters.from}
                              onChange={(e) =>
                                handleFilterChange(
                                  filter.filterOptions.queryParamOutbound,
                                  e.target.value
                                )
                              }
                            />
                          </MenuItem>
                          <MenuItem>
                            <TextField
                              size="small"
                              type="number"
                              label="To"
                              placeholder="To"
                              value={filters.to}
                              onChange={(e) =>
                                handleFilterChange(
                                  filter.filterOptions.queryParamInbound,
                                  e.target.value
                                )
                              }
                            />
                          </MenuItem>
                        </>
                      )}
                      {filter.filterOptions.type === "DATE" && (
                        <>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer
                              sx={{ padding: "10px" }}
                              components={["DateTimePicker", "DateTimePicker"]}
                            >
                              <DateTimeRangePicker
                                from_date={from_date}
                                to_date={to_date}
                                onFromDateTimeUpdate={(newFromDate) => {
                                  handleFromDateUpdate(newFromDate);
                                  handleFilterChange(
                                    filter.filterOptions.queryParamOutbound,
                                    convertToISOString(newFromDate.formatted)
                                  );
                                }}
                                onToDateTimeUpdate={(newToDate) => {
                                  handleToDateUpdate(newToDate);
                                  handleFilterChange(
                                    filter.filterOptions.queryParamInbound,
                                    convertToISOString(newToDate.formatted)
                                  );
                                }}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </>
                      )}

                      {filter.filterOptions.type === "ENUM" && (
                        <MenuItem
                          sx={{ display: "flex", flexDirection: "column" }}
                        >
                          <TextField
                            label="Search"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                          />
                          <FormGroup>
                            {filter.filterOptions.options
                              .filter((option) =>
                                option
                                  .toLowerCase()
                                  .includes(searchTerm.toLowerCase())
                              )
                              .map((option) => (
                                <FormControlLabel
                                  key={option}
                                  control={
                                    <Checkbox
                                      checked={(
                                        selectedOptionsMap[
                                          filter.filterOptions.queryParam
                                        ] || []
                                      ).includes(option)}
                                      onChange={() =>
                                        handleFilterCheckboxChange(
                                          filter.filterOptions.queryParam,
                                          option
                                        )
                                      }
                                      value={option}
                                    />
                                  }
                                  label={option}
                                />
                              ))}
                          </FormGroup>
                        </MenuItem>
                      )}
                      {filter.filterOptions.type !== "INTEGER" &&
                        filter.filterOptions.type !== "ENUM" &&
                        filter.filterOptions.type !== "DATE" && (
                          <MenuItem>
                            {/* Render the specific UI for other types of filters */}
                            <TextField
                              size="small"
                              label={filter.displayName}
                              placeholder={`Enter ${filter.displayName}`}
                              value={filters[filter.filterOptions.queryParam]}
                              onChange={(e) =>
                                handleFilterChange(
                                  filter.filterOptions.queryParam,
                                  e.target.value
                                )
                              }
                            />
                          </MenuItem>
                        )}
                      <MenuItem
                        sx={{
                          display: "flex",
                          gap: "10px",
                          justifyContent: "space-between",
                        }}
                      >
                        <Button
                          size="small"
                          variant="outlined"
                          color="inherit"
                          onClick={() =>
                            resetFilters(filter.filterOptions.queryParam)
                          }
                        >
                          Clear
                        </Button>
                        <Button
                          size="small"
                          variant="outlined"
                          color="inherit"
                          onClick={() => {
                            applyfilters();
                          }}
                        >
                          Apply
                        </Button>
                      </MenuItem>
                    </Menu>
                  </MenuItem>
                )
            )}
            <Button
              size="small"
              sx={{ marginLeft: "5px", marginRight: "5px" }}
              variant="outlined"
              color="inherit"
              onClick={reloadFilters}
            >
              <RestartAltIcon></RestartAltIcon>Reload
            </Button>
            <TextField
              size="small"
              label="Search"
              sx={{ marginLeft: "5px" }}
              value={filters.searchQuery}
              onChange={(e) =>
                handleFilterChange("searchQuery", e.target.value)
              }
            />
          </div>
          {warning && (
            <Alert severity="error">
              Something went wrong while fetching the transactions
            </Alert>
          )}
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 700, marginTop: "7px" }}
              aria-label="customized table"
            >
              <TableHead>
                <TableRow>
                  {data.ListOfShowingColumns.map((columnKey) => (
                    <StyledTableCell key={columnKey}>
                      {data.transactionsMap[columnKey].displayName}
                    </StyledTableCell>
                  ))}

                  <FormControl>
                    <Select
                      labelId="select-columns-label"
                      value=""
                      IconComponent={AddIcon}
                    >
                      {Object.keys(data.transactionsMap).map((columnKey) => (
                        <MenuItem key={columnKey} value={columnKey}>
                          <Checkbox
                            checked={checkedColumns.includes(columnKey)}
                            onChange={handleCheckboxChange(columnKey)}
                          />
                          {data.transactionsMap[columnKey].displayName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableRow>
              </TableHead>

              {isLoading ? (
                <TableBody>
                  <tr>
                    <td
                      colSpan={data.ListOfShowingColumns.length}
                      style={{ textAlign: "center" }}
                    >
                      <DNA
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="dna-loading"
                        wrapperStyle={{}}
                        wrapperClass="dna-wrapper"
                      />
                    </td>
                  </tr>
                </TableBody>
              ) : (
                <TableBody>
                  {transactions?.map((item, key) => (
                    <StyledTableRow key={key}>
                      {data.ListOfShowingColumns.map((columnKey) => (
                        <StyledTableCell key={columnKey}>
                          {renderTableCell(item, columnKey, data)}
                        </StyledTableCell>
                      ))}
                    </StyledTableRow>
                  ))}
                </TableBody>
              )}
              <TableFooter>
                <tr>
                  <td colSpan={data.ListOfShowingColumns.length}>
                    <div style={{ display: "inline-block" }}>
                      <Pagination
                        count={10}
                        page={page}
                        onChange={handleChangePage}
                      />
                    </div>
                  </td>
                </tr>
              </TableFooter>
            </Table>
          </TableContainer>
        </>
      ) : (
        <TransactionDetails
          id={clickedLinkId}
          transactionType={selectedTransactionType}
          handlingDetailsClose={handleDetailsClose}
        />
      )}
    </div>
  );
}

function getFirstNonNullValue(item, path) {
  const fields = path.split(",").map((field) => field.trim());
  for (const field of fields) {
    const value = data.getColumnValue(item, field);
    if (value !== null && value !== undefined) {
      return value;
    }
  }
  // If all fields are null or undefined, return an empty string or any default value you prefer
  return "";
}

export default Transactions;
