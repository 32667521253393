import React from 'react';

const Wallet = () => (<svg
    xmlns="https://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
  >
    <path
      d="M16.875 6.03638H4.375C4.20924 6.03638 4.05027 5.97053 3.93306 5.85332C3.81585 5.73611 3.75 5.57714 3.75 5.41138C3.75 5.24562 3.81585 5.08665 3.93306 4.96944C4.05027 4.85223 4.20924 4.78638 4.375 4.78638H15C15.1658 4.78638 15.3247 4.72053 15.4419 4.60332C15.5592 4.48611 15.625 4.32714 15.625 4.16138C15.625 3.99562 15.5592 3.83665 15.4419 3.71944C15.3247 3.60223 15.1658 3.53638 15 3.53638H4.375C3.87772 3.53638 3.40081 3.73392 3.04917 4.08555C2.69754 4.43718 2.5 4.9141 2.5 5.41138V15.4114C2.5 15.9087 2.69754 16.3856 3.04917 16.7372C3.40081 17.0888 3.87772 17.2864 4.375 17.2864H16.875C17.2065 17.2864 17.5245 17.1547 17.7589 16.9203C17.9933 16.6858 18.125 16.3679 18.125 16.0364V7.28638C18.125 6.95486 17.9933 6.63691 17.7589 6.40249C17.5245 6.16807 17.2065 6.03638 16.875 6.03638ZM16.875 16.0364H4.375C4.20924 16.0364 4.05027 15.9705 3.93306 15.8533C3.81585 15.7361 3.75 15.5771 3.75 15.4114V7.17935C3.95068 7.25049 4.16208 7.28669 4.375 7.28638H16.875V16.0364ZM13.125 11.3489C13.125 11.1635 13.18 10.9822 13.283 10.828C13.386 10.6739 13.5324 10.5537 13.7037 10.4827C13.875 10.4118 14.0635 10.3932 14.2454 10.4294C14.4273 10.4656 14.5943 10.5549 14.7254 10.686C14.8565 10.8171 14.9458 10.9841 14.982 11.166C15.0182 11.3478 14.9996 11.5363 14.9286 11.7076C14.8577 11.8789 14.7375 12.0254 14.5833 12.1284C14.4292 12.2314 14.2479 12.2864 14.0625 12.2864C13.8139 12.2864 13.5754 12.1876 13.3996 12.0118C13.2238 11.836 13.125 11.5975 13.125 11.3489Z"
      fill="#525866"
    />
  </svg>);

export default Wallet;