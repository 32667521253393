import { KCClient } from "./utils/KC.js";
import { httpClient } from "utils/HttpClient";
import { URL, LOGOUT } from "constants/Constants";

const Logout = () => {
  httpClient.postFormData(`${URL}${LOGOUT}`, "").then((response) => {
    if (KCClient) {
       KCClient.logout();
    }
  }).catch((error) => {
    if (KCClient) {
      KCClient.logout();
    }  
  });
  
};

export default Logout;
