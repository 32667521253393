import React from "react";

const Rules = () => (
  <svg
    xmlns="https://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M13.125 8.75H16.875C17.2065 8.75 17.5245 8.6183 17.7589 8.38388C17.9933 8.14946 18.125 7.83152 18.125 7.5V3.75C18.125 3.41848 17.9933 3.10054 17.7589 2.86612C17.5245 2.6317 17.2065 2.5 16.875 2.5H13.125C12.7935 2.5 12.4755 2.6317 12.2411 2.86612C12.0067 3.10054 11.875 3.41848 11.875 3.75V5H11.25C10.587 5 9.95107 5.26339 9.48223 5.73223C9.01339 6.20107 8.75 6.83696 8.75 7.5V9.375H6.25V8.75C6.25 8.41848 6.1183 8.10054 5.88388 7.86612C5.64946 7.6317 5.33152 7.5 5 7.5H2.5C2.16848 7.5 1.85054 7.6317 1.61612 7.86612C1.3817 8.10054 1.25 8.41848 1.25 8.75V11.25C1.25 11.5815 1.3817 11.8995 1.61612 12.1339C1.85054 12.3683 2.16848 12.5 2.5 12.5H5C5.33152 12.5 5.64946 12.3683 5.88388 12.1339C6.1183 11.8995 6.25 11.5815 6.25 11.25V10.625H8.75V12.5C8.75 13.163 9.01339 13.7989 9.48223 14.2678C9.95107 14.7366 10.587 15 11.25 15H11.875V16.25C11.875 16.5815 12.0067 16.8995 12.2411 17.1339C12.4755 17.3683 12.7935 17.5 13.125 17.5H16.875C17.2065 17.5 17.5245 17.3683 17.7589 17.1339C17.9933 16.8995 18.125 16.5815 18.125 16.25V12.5C18.125 12.1685 17.9933 11.8505 17.7589 11.6161C17.5245 11.3817 17.2065 11.25 16.875 11.25H13.125C12.7935 11.25 12.4755 11.3817 12.2411 11.6161C12.0067 11.8505 11.875 12.1685 11.875 12.5V13.75H11.25C10.9185 13.75 10.6005 13.6183 10.3661 13.3839C10.1317 13.1495 10 12.8315 10 12.5V7.5C10 7.16848 10.1317 6.85054 10.3661 6.61612C10.6005 6.3817 10.9185 6.25 11.25 6.25H11.875V7.5C11.875 7.83152 12.0067 8.14946 12.2411 8.38388C12.4755 8.6183 12.7935 8.75 13.125 8.75ZM5 11.25H2.5V8.75H5V11.25ZM13.125 12.5H16.875V16.25H13.125V12.5ZM13.125 3.75H16.875V7.5H13.125V3.75Z"
      fill="#CACFD8"
    />
  </svg>
);

export default Rules;
