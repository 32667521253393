import React from "react";

const HomeLine = () => (
  <svg
    xmlns="https://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M18.75 16.25H17.5V9.02736C17.5 8.85436 17.4641 8.68323 17.3946 8.52482C17.3251 8.3664 17.2234 8.22414 17.0961 8.10705L10.8461 2.21017C10.843 2.2075 10.8402 2.20463 10.8375 2.20158C10.6074 1.99231 10.3075 1.87634 9.99648 1.87634C9.68545 1.87634 9.38558 1.99231 9.15547 2.20158L9.14688 2.21017L2.90391 8.10705C2.77656 8.22414 2.67491 8.3664 2.60538 8.52482C2.53586 8.68323 2.49997 8.85436 2.5 9.02736V16.25H1.25C1.08424 16.25 0.925268 16.3159 0.808058 16.4331C0.690848 16.5503 0.625 16.7093 0.625 16.875C0.625 17.0408 0.690848 17.1997 0.808058 17.317C0.925268 17.4342 1.08424 17.5 1.25 17.5H18.75C18.9158 17.5 19.0747 17.4342 19.1919 17.317C19.3092 17.1997 19.375 17.0408 19.375 16.875C19.375 16.7093 19.3092 16.5503 19.1919 16.4331C19.0747 16.3159 18.9158 16.25 18.75 16.25ZM3.75 9.02736L3.75859 9.01955L10 3.12501L16.2422 9.01798L16.2508 9.0258V16.25H12.5V12.5C12.5 12.1685 12.3683 11.8506 12.1339 11.6161C11.8995 11.3817 11.5815 11.25 11.25 11.25H8.75C8.41848 11.25 8.10054 11.3817 7.86612 11.6161C7.6317 11.8506 7.5 12.1685 7.5 12.5V16.25H3.75V9.02736ZM11.25 16.25H8.75V12.5H11.25V16.25Z"
      fill="white"
    />
  </svg>
);

export default HomeLine;
