import React, { useEffect, useState } from "react";
import "./RulesCondition.css";
import { Button, Select, TextField } from "@mui/material";
import ElementDropdown from "../ElementDropdown";
import MultiSelect from "../MultiSelect";
import { includes, isEqual } from "lodash";
import Delete from "../../Icons/Delete";
import Group from "../../Icons/Group";
import styled from "@emotion/styled";
import Switch from "@mui/material/Switch";

const dummyJSON = [
  {
    name: "paymentMethod",
    supportedOperations: ["in", "notIn"],
    supportedValues: [
      "CARDS",
      "BANK_TRANSFER",
      "OVO",
      "GO_PAY",
      "DANA",
      "DOKU",
      "ALFAMART",
      "INDOMARET",
      "MANDIRI_CLICKPAY",
      "SHOPEEPAY",
      "SAKUKU",
      "NEXCASH",
      "QRIS",
      "AKULAKU",
      "KREDIVO",
      "LINKAJA",
      "ASTRAPAY",
      "JENIUSPAY",
      "UANGME",
      "INDODANA",
      "ATOME",
    ],
  },
  {
    name: "paymentMethodDetails.card.brand",
    supportedOperations: ["in", "notIn"],
    supportedValues: ["VISA", "MASTER_CARD", "DISCOVERY", "JCB"],
  },
  {
    name: "paymentMethodDetails.bankTransfer.bankNameCode",
    supportedOperations: ["in", "notIn"],
    supportedValues: [
      "BCA_VA",
      "MANDIRI_VA",
      "BNI_VA",
      "BRI_VA",
      "DANAMON_VA",
      "SINARMAS_VA",
      "PERMATA_VA",
      "CIMB_VA",
      "BJB_VA",
      "BNC_VA",
      "BSS_VA",
      "BSI_VA",
      "ARTAJASA_VA",
      "SAMPOERNA_VA",
    ],
  },
  {
    name: "amount.value",
    supportedOperations: [
      "greaterThan",
      "lessThan",
      "greaterThanEqual",
      "lessThanEqual",
      "equals",
    ],
    supportedValues: null,
  },
  {
    name: "amount.currencyCode",
    supportedOperations: ["in", "notIn"],
    supportedValues: ["IDR"],
  },
  {
    name: "billingAddress.country",
    supportedOperations: ["in", "notIn"],
    supportedValues: ["ID"],
  },
  {
    name: "customer.nationality",
    supportedOperations: ["in", "notIn"],
    supportedValues: ["ID"],
  },
  {
    name: "shippingDetails.address.country",
    supportedOperations: ["in", "notIn"],
    supportedValues: ["ID"],
  },
  {
    name: "transactionType",
    supportedOperations: ["in", "notIn"],
    supportedValues: ["PAYMENT", "CREDIT"],
  },
];

const OPERATION_ENUM = {
  in: "IN",
  notIn: "NOT IN",
  equals: "EQUALS",
  notEquals: "NOT EQUALS",
  greaterThan: "GREATER THAN",
  greaterThanEqual: "GREATER THAN EQUAL",
  lessThan: "LESS THAN",
  lessThanEqual: "LESS THAN EQUAL",
};

function findSubObjectById(obj, idToFind) {
  // If the current object has the id we're looking for, return it
  if (obj.id === idToFind) {
    return obj;
  }

  // If the object has a property 'conditions' which is an array, search within it
  if (Array.isArray(obj.conditions)) {
    for (const condition of obj.conditions) {
      const result = findSubObjectById(condition, idToFind);
      if (result) {
        return result; // Found the sub-object, return it
      }
    }
  }

  // If the object has a property 'condition' which is an object, search within it
  if (obj.condition && typeof obj.condition === "object") {
    return findSubObjectById(obj.condition, idToFind);
  }

  // If we reach here, it means the sub-object wasn't found at this level
  return null;
}

const modifyConditionsByLevel = (conditionObj, path, newValue, operation) => {
  // Base case: if the path is empty, perform the operation directly on the conditionObj
  if (path.length === 0) {
    if (operation === "add") {
      // Add the new condition at the current level
      return {
        ...conditionObj,
        conditions: [newValue, ...(conditionObj.conditions || [])],
      };
    } else if (operation === "update") {
      // Check if the current level has conditions to update
      if (conditionObj.conditions && Array.isArray(conditionObj.conditions)) {
        // Update the condition with the matching id
        const updatedConditions = conditionObj.conditions.map((o) => {
          if (o?.id === newValue?.id) {
            return { ...o, ...newValue };
          }
          return o;
        });
        return { ...conditionObj, conditions: updatedConditions };
      }
    } else if (operation === "delete") {
      // Since we can't delete the root, return null to indicate no operation
      if (
        conditionObj?.id &&
        newValue.condition?.id &&
        conditionObj?.id === newValue.condition?.id
      ) {
        return null;
      }
      if (conditionObj.conditions && Array.isArray(conditionObj.conditions)) {
        // Update the condition with the matching id
        const updatedConditions = conditionObj.conditions.map((o) => {
          if (o?.id === newValue?.id) {
            return null;
          }
          return o;
        });
        return {
          ...conditionObj,
          conditions: updatedConditions.filter(
            (subCondition) => subCondition !== null
          ),
        };
      }
    }
  }
  // Recursive case: if there's a path, dive into the nested conditions
  if (conditionObj.conditions && Array.isArray(conditionObj.conditions)) {
    const [currentIndex, ...restOfPath] = path;
    const updatedConditions = conditionObj.conditions
      .map((subCondition, index) => {
        if (index === currentIndex) {
          // Recurse with the rest of the path
          return modifyConditionsByLevel(
            subCondition,
            restOfPath,
            newValue,
            operation
          );
        }
        return subCondition;
      })
      .filter((subCondition) => subCondition !== null); // Filter out null values if deleting

    return { ...conditionObj, conditions: updatedConditions };
  }
  // Return the condition object if no changes are made
  return conditionObj;
};

function RulesCondition({
  data,
  level,
  setRuleData,
  ruleData,
  indexPath,
  parentObject,
  setParentObject,
  conditionError,
  setConditionError,
  ruleId,
  isEditing,
  isNested,
}) {
  const [paramDropDown, setParamDropDown] = useState([]);
  const [operationsMap, setOperationsMap] = useState(new Map());
  const [valuesMap, setValuesMap] = useState(new Map());
  const [obj, setObj] = useState(data);
  const [nestedOperation, setNestedOperation] = useState(
    obj?.nestedOperation || "AND"
  );

  useEffect(() => {
    if (
      conditionError &&
      !isEqual(ruleData.condition, obj.condition) &&
      isEqual(ruleData.id, ruleId)
    ) {
      if (parentObject && obj.condition.id) {
        const subObj = findSubObjectById(ruleData, obj.condition.id);
        setObj({ condition: { ...subObj } });
      } else setObj({ condition: { ...ruleData.condition } });
      setConditionError(false);
    }
  }, [conditionError]);

  useEffect(() => {
    setParamDropDown(dummyJSON?.map((obj) => obj.name));
    const oMap = new Map();
    const vMap = new Map();
    dummyJSON.forEach((item) => {
      const refactorOperations = item.supportedOperations.map((i) => ({
        label: OPERATION_ENUM[i],
        value: i,
      }));
      const refactorSupportedValues = item.supportedValues?.map((sv) => ({
        label: sv,
        value: sv,
      }));
      oMap.set(item.name, refactorOperations);
      vMap.set(item.name, refactorSupportedValues);
    });
    setOperationsMap(oMap);
    setValuesMap(vMap);
  }, [dummyJSON.length]);

  const handleClick = (value, obj, item = {}) => {
    switch (value) {
      case "S":
        let o = {
          id: new Date().getTime(),
          param: obj.param,
          operation: obj.operation,
          value: obj?.value,
          isError: false,
          errorMessage: "",
        };

        const updatedObj = {
          ...obj,
          condition: {
            ...obj.condition,
            conditions: [o, ...(obj.condition?.conditions || [])],
          },
        };
        setObj(updatedObj);
        if (parentObject) {
          setParentObject((prev) => {
            const newObj = { ...prev };
            let currentLevel = newObj.condition.conditions; // Start at the first level

            // Iterate over the indexPath array, except for the last element
            for (let i = 0; i < indexPath.length - 1; i++) {
              // Ensure that the next level exists or create it if necessary
              if (!currentLevel[indexPath[i]].conditions) {
                currentLevel[indexPath[i]].conditions = [];
              }
              // Move to the next level
              currentLevel = currentLevel[indexPath[i]].conditions;
            }

            // Update the last level with the updated object
            currentLevel[indexPath[indexPath.length - 1]] =
              updatedObj.condition;
            return newObj;
          });
        }
        setRuleData((prevObject) => {
          const newObject = { ...prevObject };
          const updatedCondition = modifyConditionsByLevel(
            newObject.condition,
            indexPath,
            o,
            "add"
          );
          return { ...prevObject, condition: updatedCondition };
        });
        break;
      case "N":
        let ob = {
          id: new Date().getTime(),
          nestedOperation: nestedOperation,
          conditions: [
            {
              id: new Date().getTime(),
              param: obj.param,
              operation: obj.operation,
              value: obj?.value,
              isError: false,
              errorMessage: "",
            },
          ],
        };
        const updatedObj1 = {
          ...obj,
          condition: {
            ...obj.condition,
            conditions: [ob, ...(obj.condition.conditions || [])],
          },
        };
        setObj(updatedObj1);
        if (parentObject) {
          setParentObject((prev) => {
            const newObj = { ...prev };
            let currentLevel = newObj.condition.conditions; // Start at the first level

            // Iterate over the indexPath array, except for the last element
            for (let i = 0; i < indexPath.length - 1; i++) {
              // Ensure that the next level exists or create it if necessary
              if (!currentLevel[indexPath[i]].conditions) {
                currentLevel[indexPath[i]].conditions = [];
              }
              // Move to the next level
              currentLevel = currentLevel[indexPath[i]].conditions;
            }

            // Update the last level with the updated object
            currentLevel[indexPath[indexPath.length - 1]] =
              updatedObj1.condition;
            return newObj;
          });
        }
        setRuleData((prevObject) => {
          const newObject = { ...prevObject };
          const updatedCondition = modifyConditionsByLevel(
            newObject.condition,
            indexPath,
            ob,
            "add"
          );
          return { ...prevObject, condition: updatedCondition };
        });
        break;
      case "R":
        let updatedObj2 = {
          ...obj,
          condition: {
            ...obj.condition,
            conditions: obj.condition.conditions
              .map((i) => {
                if (i?.id === item?.id) {
                  return null;
                }
                return i;
              })
              .filter((subCondition) => subCondition !== null),
          },
        };
        if (
          obj?.condition?.id &&
          item?.condition?.id &&
          obj?.condition?.id === item?.condition?.id
        ) {
          setObj(null);
        } else {
          setObj(updatedObj2);
        }
        if (parentObject) {
          setParentObject((prev) => {
            const newObj = { ...prev };
            let currentLevel = newObj.condition.conditions; // Start at the first level
            // Iterate over the indexPath array, except for the last element
            for (let i = 0; i < indexPath.length - 1; i++) {
              // Ensure that the next level exists or create it if necessary
              if (!currentLevel[indexPath[i]].conditions) {
                currentLevel[indexPath[i]].conditions = [];
              }
              // Move to the next level
              currentLevel = currentLevel[indexPath[i]].conditions;
            }
            // Update the last level with the updated object
            currentLevel[indexPath[indexPath.length - 1]] =
              obj?.condition?.id &&
              item?.condition?.id &&
              obj?.condition?.id === item?.condition?.id
                ? null
                : updatedObj2.condition;
            return {
              condition: {
                ...newObj.condition,
                conditions: newObj.condition.conditions.filter(
                  (subCondition) => subCondition !== null
                ),
              },
            };
          });
        }
        setRuleData((prevObject) => {
          const newObject = { ...prevObject };
          const updatedCondition = modifyConditionsByLevel(
            newObject.condition,
            indexPath,
            item,
            "delete"
          );
          return { ...prevObject, condition: updatedCondition };
        });
        break;
      default:
        break;
    }
  };

  const onChangeCallback = (value, item, label) => {
    const updatedObj = {
      ...obj,
      condition: {
        ...obj.condition,
        conditions: obj.condition.conditions.map((o) => {
          if (o?.id === item?.id) {
            return { ...o, [label]: value[label] };
          } else return o;
        }),
      },
    };
    setObj(updatedObj);
    setRuleData((prevObject) => {
      const newObject = { ...prevObject };
      const updatedCondition = modifyConditionsByLevel(
        newObject.condition,
        indexPath,
        value,
        "update"
      );
      return { ...prevObject, condition: updatedCondition };
    });
  };
  const [andText, setAndText] = useState("AND");
  const [orText, setOrText] = useState("OR");
  const handleTextChange = (e, type) => {
    const newValue = e.target.innerText.trim();
    if (type === "AND") {
      setAndText(newValue);
      if (nestedOperation === andText) {
        setNestedOperation(newValue);
      }
    } else {
      setOrText(newValue);
      if (nestedOperation === orText) {
        setNestedOperation(newValue);
      }
    }
  };

  const handleToggleChange = () => {
    setNestedOperation(nestedOperation === andText ? orText : andText);
  };

  return (
    <>
      {obj ? (
        <div className={isNested ? "" : "rules-condition-parent-container"}>
          <>
            <ul className="rules-condition-container" key={ruleId}>
              <div className="rules-header">
                <p
                  contentEditable
                  suppressContentEditableWarning
                  style={{
                    fontWeight: nestedOperation === andText ? "bold" : "normal",
                    padding: "5px",
                  }}
                  onBlur={(e) => handleTextChange(e, "AND")}
                >
                  {andText}
                </p>
                <div onClick={handleToggleChange}>
                  <input
                    className="apple-switch"
                    type="checkbox"
                    checked={nestedOperation === orText}
                    readOnly
                  />
                </div>
                <p
                  contentEditable
                  suppressContentEditableWarning
                  style={{
                    fontWeight: nestedOperation === orText ? "bold" : "normal",
                    padding: "5px",
                  }}
                  onBlur={(e) => handleTextChange(e, "OR")}
                >
                  {orText}
                </p>

                <button
                  disabled={!isEditing}
                  onClick={() => handleClick("S", obj)}
                >
                  Add condition
                </button>
                <button
                  disabled={!isEditing}
                  onClick={() => handleClick("N", obj)}
                >
                  Add nested condition
                </button>
                <div
                  disabled={!isEditing}
                  color="error"
                  onClick={() => {
                    handleClick("R", obj, obj);
                  }}
                  variant="outlined"
                >
                  <Delete />
                </div>
              </div>
              <div className={`rules-condition-nested-container`}>
                {obj?.condition?.conditions?.map((item, key) => {
                  return item?.nestedOperation ? (
                    <RulesCondition
                      key={item.id}
                      data={{ condition: item }}
                      level={level + 1}
                      setRuleData={setRuleData}
                      ruleData={ruleData}
                      indexPath={[...indexPath, key]}
                      parentObject={parentObject ? parentObject : obj}
                      setParentObject={
                        setParentObject ? setParentObject : setObj
                      }
                      conditionError={conditionError}
                      setConditionError={setConditionError}
                      ruleId={ruleId}
                      isEditing={isEditing}
                      isNested={true}
                    />
                  ) : (
                    <div className="rule-conditions-nested-container-parent">
                      <Group />
                      {`${nestedOperation}`}
                      <div className="rule-condition-nested-elements">
                        <ElementDropdown
                          data={item}
                          key={`${item?.id}-param`}
                          value={item?.param}
                          dropdownData={paramDropDown}
                          onChangeCallback={(value) =>
                            onChangeCallback(value, item, "param")
                          }
                          type="param"
                          error={includes(
                            item?.errorMessage,
                            "Param cannot be empty"
                          )}
                          errorMessage={"Param cannot be empty"}
                          disabled={!isEditing}
                        />
                        <ElementDropdown
                          data={item}
                          key={`${item?.id}-operation`}
                          value={item?.operation}
                          dropdownData={operationsMap?.get(item?.param)}
                          onChangeCallback={(value) =>
                            onChangeCallback(value, item, "operation")
                          }
                          type="operation"
                          disabled={isEditing && item?.param ? false : true}
                          error={includes(
                            item?.errorMessage,
                            "Operation cannot be empty"
                          )}
                          errorMessage={"Operation cannot be empty"}
                        />
                        {valuesMap?.get(item?.param) ? (
                          <MultiSelect
                            key={`${item?.id}-value`}
                            options={valuesMap?.get(item?.param) || []}
                            selectedValues={
                              item?.value
                                ?.split(",")
                                ?.map((i) => ({ label: i, value: i }))
                                ?.filter((i) => i.value !== "") || []
                            }
                            onChange={(value) => {
                              const updatedValue = value
                                .filter((i) => i.value !== "")
                                .map((i) => i.value)
                                .join();
                              const newVal = { ...item, value: updatedValue };
                              onChangeCallback(newVal, item, "value");
                            }}
                            disabled={isEditing && item?.param ? false : true}
                            error={includes(
                              item?.errorMessage,
                              "Value cannot be empty"
                            )}
                            errorMessage={"value cannot be empty"}
                          />
                        ) : (
                          <TextField
                            size="small"
                            type="number"
                            style={{ borderRadius: "8px" }}
                            value={item?.value}
                            disabled={isEditing && item?.param ? false : true}
                            onChange={(e) => {
                              onChangeCallback(
                                { ...item, value: e.target.value },
                                item,
                                "value"
                              );
                            }}
                          />
                        )}
                        <div
                          color="error"
                          variant="outlined"
                          onClick={() => handleClick("R", obj, item)}
                          disabled={!isEditing}
                        >
                          <Delete />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </ul>
          </>
        </div>
      ) : null}
    </>
  );
}

export default RulesCondition;
