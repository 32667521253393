import React, { useState } from "react";
import paymentTypeIcons from "utils/PaymentTypeIcons";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  Button,
  Tooltip,
  Menu,
  MenuItem,
} from "@mui/material";
import "./ProcessorSettings.css";
import { StyledTableCell, StyledTableRow } from "utils/Styles";

const ProcessorSettings = ({ settingsData, onEditClick, onStatusClick }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setSelectedIndex(index);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedIndex(null);
  };
  const handleEdit = (processor) => {
    onEditClick(processor);
    handleClose();
  };

  const handleStatus = (processorId, currentStatus) => {
    onStatusClick(processorId, currentStatus);
    handleClose();
  };
  return (
    <div className="processor-settings-container">
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 700, marginTop: "7px" }}
          aria-label="customized table"
        >
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Processor</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Payment Methods</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Manage</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {settingsData.processorProperties.map((property, index) => (
              <React.Fragment key={index}>
                <StyledTableRow>
                  <StyledTableCell>{property.processorCode}</StyledTableCell>
                  <StyledTableCell>{settingsData?.name}</StyledTableCell>
                  <StyledTableCell>
                    {property.paymentTypes &&
                    Object.keys(property.paymentTypes).length > 0
                      ? Object.keys(property.paymentTypes).map(
                          (paymentType) =>
                            property.paymentTypes[paymentType].status ===
                              "ACTIVE" && (
                              <Tooltip key={paymentType} title={paymentType}>
                                <img
                                  src={
                                    paymentTypeIcons[paymentType] ||
                                    "https://via.placeholder.com/54"
                                  }
                                  alt={paymentType}
                                  style={{
                                    marginRight: "5px",
                                    padding: "7px",
                                    height: "30px",
                                    width: "40px",
                                  }}
                                />
                              </Tooltip>
                            )
                        )
                      : "N/A"}
                  </StyledTableCell>
                  <StyledTableCell>{property.status}</StyledTableCell>
                  <StyledTableCell>
                    <Button
                      variant="outlined"
                      color="inherit"
                      onClick={(event) => handleClick(event, index)}
                    >
                      <b>...</b>
                    </Button>
                    <Menu
                      anchorEl={anchorEl}
                      open={selectedIndex === index}
                      onClose={handleClose}
                    >
                      <MenuItem
                        onClick={() => handleEdit(property.processorCode)}
                      >
                        Edit
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          handleStatus(property.id, property.status)
                        }
                      >
                        {property.status === "DISABLED"
                          ? "Activate"
                          : "Disable"}
                      </MenuItem>
                    </Menu>
                  </StyledTableCell>
                </StyledTableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ProcessorSettings;
