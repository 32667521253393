import { useTheme } from "@mui/material/styles";
import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import React, { useEffect, useReducer, useState } from "react";

function ChipDropdownSelector({
  processordata,
  paymentMethod,
  currencies,
  onChangeCallback,
}) {
  console.log(processordata);
  const dropdownDataReducer = (state, action) => {
    switch (action.type) {
      case "INITIALIZE":
        let obj =
          processordata?.[0]?.paymentMethods?.[paymentMethod]?.currencies;
        return obj;
      default:
        return state;
    }
  };

  const [dropdownDataState, dispatchDropdownData] = useReducer(
    dropdownDataReducer,
    []
  );

  useEffect(() => {
    dispatchDropdownData({ type: "INITIALIZE" });
  }, []);

  const [selectedItem, setSelectedItem] = useState(
    currencies?.currencies || []
  );

  // console.log(data, paymentMethod);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 300,
        minWidth: 100,
      },
    },
  };

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const theme = useTheme();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    let arr = typeof value === "string" ? value.split(",") : value;
    setSelectedItem(arr);
    onChangeCallback(arr, paymentMethod);
  };

  return (
    dropdownDataState?.length > 0 && (
      <div>
        <FormControl sx={{ m: 1, width: 300, minWidth: 100 }}>
          <InputLabel id="demo-multiple-chip-label">Currencies</InputLabel>
          <Select
            labelId="demo-multiple-chip-label"
            id="demo-multiple-chip"
            multiple
            value={selectedItem}
            onChange={handleChange}
            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value, index) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {dropdownDataState?.map((name) => (
              <MenuItem
                key={name}
                value={name}
                style={getStyles(name, selectedItem, theme)}
              >
                {name}
              </MenuItem>
            ))}
          </Select>
          {/* <button onClick={() => }> */}
          {/* Update
          </button> */}
        </FormControl>
      </div>
    )
  );
}

export default ChipDropdownSelector;
