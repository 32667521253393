import React from "react";
import { processors } from "../../../utils/processors";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function ProcessorsAccordion({ processor }) {
  return (
    <div>
      {processors
        .filter(
          (item) =>
            item.displayName.toLowerCase() ===
            (processor.toLowerCase() ||
              item.displayName.toLowerCase() === "default")
        )
        .map((processor, index) => (
          <>
            <div>
              {processor.info.map((info, index) => (
                <Accordion key={index}>
                  <AccordionSummary
                    sx={{
                      backgroundColor: "#4e515c",
                      color: "white",
                      height: "10px",
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${index}a-content`}
                    id={`panel${index}a-header`}
                  >
                    <h3>{info.title}</h3>
                  </AccordionSummary>
                  <AccordionDetails style={{ textAlign: "left" }}>
                    <p>
                      {info.content &&
                        info.content.map((htmlString, subIndex) => (
                          <div
                            key={subIndex}
                            dangerouslySetInnerHTML={{ __html: htmlString }}
                          />
                        ))}
                    </p>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </>
        ))}
    </div>
  );
}

export default ProcessorsAccordion;
