import Rules from "components/Rules/Rules";
import Transactions from "components/Transactions/Transactions";
import Developers from "components/Developers/Developers";
import Logout from "../Logout";
import HomeSettings from "../components/Home/Settings";
import TransactionsIcon from "components/Icons/Transactions";
import DevlopersIcon from "components/Icons/Developers";
import RulesIcon from "components/Icons/Rules";
import HomeIcon from "components/Icons/HomeLine";
import SignOut from "components/Icons/SignOut";

export const MenuItems = [
  {
    name: "Home",
    icon: <HomeIcon />,
    label: "Home",
    component: <HomeSettings />,
  },
  {
    name: "Rules",
    icon: <RulesIcon />,
    label: "Rules",
    component: <Rules />,
  },
  {
    name: "Transactions",
    icon: <TransactionsIcon />,
    label: "Transactions",
    component: <Transactions />,
  },

  {
    name: "Developers",
    icon: <DevlopersIcon />,
    label: "Developers",
    component: <Developers />,
  },

  {
    name: "Logout",
    icon: <SignOut />,
    label: "Logout",
    component: <Logout />,
  },
];
