import React, { useLayoutEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { httpClient } from "utils/HttpClient";
import {
  TableContainer,
  Paper,
  Table,
  Tab,
  Tabs,
  TableRow,
  TableBody,
  TableCell,
  Button,
  Box,
  Alert,
} from "@mui/material";
import {
  URL,
  MERCHANT_ID,
  TRANSACTION_DETAILS_ENDPOINT,
} from "constants/Constants";
import processorIcons from "utils/ProcessorIcons";
import { StyledTableCell, StyledTableRow } from "utils/Styles";
import Logout from "Logout";

const TransactionDetails = ({ id, transactionType, handlingDetailsClose }) => {
  console.log(transactionType);
  useLayoutEffect(() => {
    fetchTransactionData();
  }, []);
  const [transactions, setTransactions] = useState([]);
  const [tableHeadings, setTableHeadings] = useState([]);
  const [expandedCapture, setExpandedCapture] = useState(null);
  const [expandedRefund, setExpandedRefund] = useState(null);
  const [warning, setWarning] = useState(false);

  const handleClose = () => {
    handlingDetailsClose();
  };
  const handleCaptureClick = (newValue) => {
    setExpandedCapture(newValue === expandedCapture ? null : newValue);
  };

  const handleRefundClick = (newValue) => {
    setExpandedRefund(newValue === expandedRefund ? null : newValue);
  };
  const camelToWords = (camelCaseString) =>
    camelCaseString
      .replace(/([A-Z])/g, " $1")
      .trim()
      .replace(/^\w/, (c) => c.toUpperCase());

  const fetchTransactionData = () => {
    httpClient
      .fetchData(
        `${URL}${TRANSACTION_DETAILS_ENDPOINT}?merchant_id=${MERCHANT_ID}&type=payment&transactionid=${id}`,
        "GET"
      )
      .then((response) => {
        // Check if the status code is 200
        setTransactions(response);
        if (response.length > 0) {
          setTableHeadings(Object.keys(response[0]));
          // Check if the status code is 401
          if (response.status === 401) {
            Logout();
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors here
      });
  };
  return (
    <>
      <div>
        {warning && (
          <Alert severity="error">
            Something went wrong while fetching the transactions
          </Alert>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            border: "1px solid #ccc",
            borderRadius: "8px",
            padding: "16px",
            marginBottom: "16px",
          }}
        >
          <div>
            <h3>Processor</h3>
            <img
              style={{ height: 48, width: 48 }}
              src={processorIcons[transactions?.processorCode] || null}
            />
          </div>
          <div>
            <h3>Status</h3>
            <p>{transactions?.status}</p>
          </div>
          <div>
            <h3>Amount</h3>
            <p>
              {transactions?.amount?.currencyCode} {transactions?.amount?.value}
            </p>
          </div>
          <div>
            <h3>Reference number</h3>
            <p>{transactions?.merchantReferenceId}</p>
          </div>
          <div>
            <h3>Processor Recon Id</h3>
            <p>{transactions?.processorReconId}</p>
          </div>
          <div>
            <h3>Processor Transaction Id</h3>
            <p>{transactions?.processorTransactionId}</p>
          </div>
        </Box>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
            sx={{
              backgroundColor: "#4e515c",
              color: "white",
              height: "10px",
            }}
          >
            Customer Details
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 700, marginTop: "7px" }}
                aria-label="customized table"
              >
                <TableBody>
                  {transactions &&
                    transactions.customer &&
                    Object.entries(transactions.customer)?.map(
                      ([key, value], index) => (
                        <StyledTableRow key={index}>
                          <StyledTableCell>{camelToWords(key)}</StyledTableCell>
                          <StyledTableCell>
                            {typeof value === "object"
                              ? JSON.stringify(value)
                              : value}
                          </StyledTableCell>
                        </StyledTableRow>
                      )
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
            sx={{
              backgroundColor: "#4e515c",
              color: "white",
              height: "10px",
            }}
          >
            Payment Instrument Details
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 700, marginTop: "7px" }}
                aria-label="customized table"
              >
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell>Payment Method</StyledTableCell>
                    <StyledTableCell>
                      {/* <img
                      style={{ height: 48, width: 48 }}
                      src={
                        paymentTypeIcons[transactions?.paymentMethod] || null
                      }
                      alt="Payment Method"
                    />{" "} */}
                      {transactions?.paymentMethod}
                    </StyledTableCell>
                  </StyledTableRow>
                  {transactions?.paymentMethodDetails?.card && (
                    <>
                      <StyledTableRow>
                        <StyledTableCell>Card Brand</StyledTableCell>
                        <StyledTableCell>
                          {transactions?.paymentMethodDetails?.card?.brand}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell>Card Number</StyledTableCell>
                        <StyledTableCell>
                          **** **** ****{" "}
                          {transactions?.paymentMethodDetails?.card?.last4}
                        </StyledTableCell>
                      </StyledTableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
            sx={{
              backgroundColor: "#4e515c",
              color: "white",
              height: "10px",
            }}
          >
            Information
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 700, marginTop: "7px" }}
                aria-label="customized table"
              >
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell>Description</StyledTableCell>
                    <StyledTableCell>
                      {transactions?.description}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>Creation Date</StyledTableCell>
                    <StyledTableCell>
                      {transactions?.transactionTime}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
        {transactions &&
          transactions.captures &&
          transactions.captures.length > 0 && (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{
                  backgroundColor: "#4e515c",
                  color: "white",
                  height: "10px",
                }}
              >
                Captures
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer component={Paper}>
                  <Table
                    sx={{ minWidth: 700, marginTop: "7px" }}
                    aria-label="customized table"
                  >
                    <TableBody>
                      <Tabs
                        value={expandedCapture}
                        onChange={(event, newValue) =>
                          handleCaptureClick(newValue)
                        }
                        variant="scrollable"
                        scrollButtons="auto"
                      >
                        {transactions?.captures &&
                          Object.keys(transactions.captures).map((key) => (
                            <Tab
                              key={key}
                              label={`Capture ${Number(key) + 1}`}
                              value={key}
                            />
                          ))}
                      </Tabs>

                      {expandedCapture && (
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-around",
                              border: "1px solid #ccc",
                              borderRadius: "8px",
                              padding: "16px",
                              marginBottom: "16px",
                            }}
                          >
                            <div>
                              <h3>Status</h3>
                              <p>
                                {
                                  transactions?.captures[expandedCapture]
                                    ?.status
                                }
                              </p>
                            </div>
                            <div>
                              <h3>Amount</h3>
                              <p>
                                {
                                  transactions?.captures[expandedCapture]
                                    ?.amount?.currencyCode
                                }{" "}
                                {
                                  transactions?.captures[expandedCapture]
                                    ?.amount?.value
                                }
                              </p>
                            </div>
                            <div>
                              <h3>Reference number</h3>
                              <p>
                                {
                                  transactions?.captures[expandedCapture]
                                    ?.merchantReferenceId
                                }
                              </p>
                            </div>
                            <div>
                              <h3>Processor Recon Id</h3>
                              <p>
                                {
                                  transactions?.captures[expandedCapture]
                                    ?.processorReconId
                                }
                              </p>
                            </div>
                            <div>
                              <h3>Processor Transaction Id</h3>
                              <p>
                                {
                                  transactions?.captures[expandedCapture]
                                    ?.processorTransactionId
                                }
                              </p>
                            </div>
                          </Box>
                        </>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                    sx={{
                      backgroundColor: "#4e515c",
                      color: "white",
                      height: "10px",
                    }}
                  >
                    Information
                  </AccordionSummary>
                  <AccordionDetails>
                    <TableContainer component={Paper}>
                      <Table
                        sx={{ minWidth: 700, marginTop: "7px" }}
                        aria-label="customized table"
                      >
                        <TableBody>
                          <StyledTableRow>
                            <StyledTableCell>Description</StyledTableCell>
                            <StyledTableCell>
                              {
                                transactions?.captures[expandedCapture]
                                  ?.description
                              }
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow>
                            <StyledTableCell>Creation Date</StyledTableCell>
                            <StyledTableCell>
                              {
                                transactions?.captures[expandedCapture]
                                  ?.transactionTime
                              }
                            </StyledTableCell>
                          </StyledTableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </AccordionDetails>
                </Accordion>
              </AccordionDetails>
            </Accordion>
          )}

        {transactions &&
          transactions.refunds &&
          transactions.refunds.length > 0 && (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
                sx={{
                  backgroundColor: "#4e515c",
                  color: "white",
                  height: "10px",
                }}
              >
                Refunds
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer component={Paper}>
                  <Table
                    sx={{ minWidth: 700, marginTop: "7px" }}
                    aria-label="customized table"
                  >
                    <TableBody>
                      <Tabs
                        value={expandedRefund}
                        onChange={(event, newValue) =>
                          handleRefundClick(newValue)
                        }
                        variant="scrollable"
                        scrollButtons="auto"
                      >
                        {transactions?.refunds &&
                          Object.keys(transactions.refunds).map((key) => (
                            <Tab
                              key={key}
                              label={`Refund ${Number(key) + 1}`}
                              value={key}
                            />
                          ))}
                      </Tabs>

                      {expandedRefund && (
                        <>
                          <TableRow>
                            <TableCell>Processor</TableCell>
                            <TableCell>
                              <img
                                style={{ height: 48, width: 48 }}
                                src={
                                  processorIcons[
                                    transactions?.refunds[expandedRefund]
                                      ?.processorCode
                                  ] || null
                                }
                                alt="Processor"
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Status</TableCell>
                            <TableCell>
                              {transactions?.refunds[expandedRefund]?.status}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Amount</TableCell>
                            <TableCell>
                              {
                                transactions?.refunds[expandedRefund]?.amount
                                  ?.currencyCode
                              }{" "}
                              {
                                transactions?.refunds[expandedRefund]?.amount
                                  ?.value
                              }
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Reference number</TableCell>
                            <TableCell>
                              {
                                transactions?.refunds[expandedRefund]
                                  ?.merchantReferenceId
                              }
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Processor Recon Id</TableCell>
                            <TableCell>
                              {
                                transactions?.refunds[expandedRefund]
                                  ?.processorReconId
                              }
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          )}

        <Button onClick={handleClose}>Back</Button>
      </div>
    </>
  );
};

export default TransactionDetails;
