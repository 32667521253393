// processor.js
import { MERCHANT_ID } from "constants/Constants";
export const processors = [
  {
    displayName: "Midtrans",
    processorCode: "MIDTRANS",
    logo_url: "logos/midtrans.png",
    info: [
      {
        title: "Fetch API Keys",
        content: [
          '<p>1. Login to Midtrans Dashboard (<a href="https://dashboard.sandbox.midtrans.com/" target="_blank">https://dashboard.sandbox.midtrans.com/</a>)</p>',
          "<p>2. Navigate to Access Keys under Settings section from left side menu.</p>",
          "<p>3. Copy the Merchant ID, Client Key and Secret Key and configure Payorch Midtrans API credentials block.</p>",
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          '<p>1. Login to Midtrans Dashboard (<a href="https://dashboard.sandbox.midtrans.com/" target="_blank">https://dashboard.sandbox.midtrans.com/</a>)</p>',
          "<p>2. Navigate to Payment section under Settings section from left side menu.</p>",
          "<p>3. Click on Payment Settings -&gt; Notification URL</p>",
          `<p>4. Configure the following webhook URL, for Payment Notification URL<br>  <a>https://api.prtest.payorch.com/midtrans/${MERCHANT_ID}/webhooks</a></p>`,
        ],
      },
      {
        title: "Setup Return URLs",
        content: [
          '<p>1. Login to Midtrans Dashboard (<a href="https://dashboard.sandbox.midtrans.com/" target="_blank">https://dashboard.sandbox.midtrans.com/</a>)</p>',
          "<p>2. Navigate to Payment section under Settings section from the left side menu.</p>",
          "<p>3. Click on Payment Settings -&gt; Finish Redirect URL</p>",
          `<p>4. Configure the following redirect URL for Finish Redirect URL:<br>  <a>https://api.prtest.payorch.com/return/midtrans?merchantid=${MERCHANT_ID}</a></p>`,
        ],
      },
    ],
  },
  {
    displayName: "Adyen",
    processorCode: "ADYEN",
    logo_url: "logos/adyen.png",
    info: [
      {
        title: "Fetch API Keys",
        content: [
          "<p>1. Login to Adyen Dashboard (<a href='https://ca-test.adyen.com/ca/ca/' target='_blank'>https://ca-test.adyen.com/ca/ca/</a>)</p>",
          "<p>2. Navigate to API credentials under Developers section from the left side menu.</p>",
          "<p>3. Create new credentials of type Web service user, if not already exists.</p>",
          "<p>4. Configure sufficient roles for the API while generating. Basic roles require all the webhook-related roles under the ACCOUNT section and all the roles under UNCATEGORIZED.</p>",
          "<p>5. Copy the username and API Key and configure them in the Payorch Adyen API credentials block.</p>",
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          "<p>1. Login to Adyen Dashboard (<a href='https://ca-test.adyen.com/ca/ca/' target='_blank'>https://ca-test.adyen.com/ca/ca/</a>)</p>",
          "<p>2. Navigate to Webhooks under the Developers section from the left side menu.</p>",
          "<p>3. Add a new webhook configuration. Select Standard Webhook and choose all the event types.</p>",
          `<p>4. Configure the following webhook URL for Payment Notification URL:<br>  <a>https://api.prtest.payorch.com/adyen/${MERCHANT_ID}/webhooks</a></p>`,
        ],
      },
      {
        title: "Configure Additional Data",
        content: [
          "<p>1. Login to Adyen Dashboard (<a href='https://ca-test.adyen.com/ca/ca/' target='_blank'>https://ca-test.adyen.com/ca/ca/</a>)</p>",
          "<p>2. Navigate to Additional data under the Developers section from the left side menu.</p>",
          "<p>3. Select all the required data. Bank, Card, and 3DSecure are mandatory.</p>",
        ],
      },
    ],
  },
  {
    displayName: "XENDIT",
    processorCode: "XENDIT",
    logo_url: "logos/xendit-logo.jpg",
    info: [
      {
        title: "Fetch API Keys",
        content: [
          "<p>1. Login to Xendit Dashboard (<a href='https://dashboard.xendit.co/home' target='_blank'>https://dashboard.xendit.co/home</a>)</p>",
          "<p>2. Select Settings under Configuration section on the left side menu.</p>",
          "<p>3. Go to the Developers block and click API keys.</p>",
          "<p>4. Click on Generate Secret key. While generating the secret key, select Write permissions for Money-in (required for payments) and Money-out (required for payments) products.</p>",
          "<p>5. Copy the API Key and set it to the API key in the Payorch Xendit API credentials block.</p>",
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          "<p>1. Login to Xendit Dashboard (<a href='https://dashboard.xendit.co/home' target='_blank'>https://dashboard.xendit.co/home</a>)</p>",
          "<p>2. Select Settings under Configuration section on the left side menu.</p>",
          "<p>3. Go to the Developers block and click Webhooks.</p>",
          `<p>4. Set the following webhook URL for the following products: FIXED VIRTUAL ACCOUNTS, RETAIL OUTLETS (OTC), CARDS, PAYMENT REQUEST, Unified Refunds, PAYLATER, QR CODES, DISBURSEMENT, and E-WALLETS.<br> <a>https://api.prtest.payorch.com/xendit/{$MERCHANT_ID}/webhooks</a></p>`,
        ],
      },
    ],
  },
  {
    displayName: "DOKU",
    processorCode: "DOKU",
    logo_url: "logos/doku-logo.jpg",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          "<p>1. Login to DOKU Dashboard (<a href='https://sandbox.doku.com/bo/login' target='_blank'>https://sandbox.doku.com/bo/login</a>)</p>",
          "<p>2. Navigate to API Keys under the Integration section from the left side menu.</p>",
          "<p>3. Copy the Client ID and Secret Key and configure them in the Payorch DOKU API credentials block.</p>",
        ],
      },
    ],
  },
  {
    displayName: "IPaymu",
    processorCode: "IPAYMU",
    logo_url: "logos/ipaymu-logo.png",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          "<p>1. Login to IPaymu Dashboard (<a href='https://my.ipaymu.com/dashboard' target='_blank'>https://my.ipaymu.com/dashboard</a>)</p>",
          "<p>2. Navigate to API Integration from the left side menu.</p>",
          "<p>3. Copy the VA Account number and API Key, and configure them in the Payorch IPaymu API credentials.</p>",
        ],
      },
    ],
  }
];
export const ProcessorIcon = ({ processorCode }) => {
  const processor = processors.find(
    (p) => p.displayName?.toLowerCase() === processorCode?.toLowerCase()
  );

  if (processor) {
    console.log(processor);
    return <img src={processor.logo_url} alt={processor.displayName} />;
  }
};
