import React from "react";
import { Box, Typography } from "@mui/material";
import ApiCredentials from "./ApiCredentials/ApiCredentials";
import Webhooks from "./Webhooks/Webhooks";
import CheckoutSettings from "./CheckoutSettings/CheckoutSettings";
import "./Developers.css";

const Developers = () => {
  return (
    <div className="developer-container">
      <Typography variant="h5" gutterBottom align="left">
        API Credentials
      </Typography>
      <Box mb={3}>
        <ApiCredentials />
      </Box>
      <Typography variant="h5" gutterBottom align="left">
        Webhooks
      </Typography>
      <Box border={1} borderColor="grey.400" borderRadius={2} p={2} mb={2}>
        <Webhooks />
      </Box>
      <Typography variant="h5" gutterBottom align="left">
        Checkout Settings
      </Typography>
      <Box border={1} borderColor="grey.400" borderRadius={2} p={2}>
        <CheckoutSettings />
      </Box>
    </div>
  );
};

export default Developers;
