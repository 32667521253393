import React, { useState, useEffect } from "react";
import { TextField, Grid, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import "./CheckoutSettings.css";
import { httpClient } from "utils/HttpClient";
import {
  URL,
  CHECKOUT_SETTINGS_ENDPOINT,
  MERCHANT_ID,
} from "constants/Constants";
import Logout from "Logout";
function CheckoutSettings() {
  const [logoUrl, setLogoUrl] = useState("");
  const [title, setTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    fetchCheckoutSettingsData();
  }, []);

  const fetchCheckoutSettingsData = async () => {
    try {
      const fetchedData = await httpClient.fetchData(
        `${URL}${CHECKOUT_SETTINGS_ENDPOINT}?merchant_id=${MERCHANT_ID}`,
        "GET"
      );

      setLogoUrl(fetchedData.logoUrl || "");
      setTitle(fetchedData.title || "");
    } catch (error) {
      console.error("Error fetching checkout settings:", error);
    }
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);
      const response = await httpClient.fetchData(
        `${URL}${CHECKOUT_SETTINGS_ENDPOINT}?merchant_id=${MERCHANT_ID}`,
        "POST",
        {
          logoUrl,
          title,
        }
      );

      if (response.ok) {
        return response.json();
      } else {
        // Check if the status code is 401
        if (response.status === 401) {
          Logout();
        } else {
          console.log("Failed saving data:", response.status);
        }
      }
    } catch (error) {
      console.error("Error saving data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="webhooks-container">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Typography variant="subtitle1">Logo URL</Typography>
            </Grid>
            <Grid item xs={10}>
              <TextField
                fullWidth
                value={logoUrl}
                onChange={(e) => setLogoUrl(e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Typography variant="subtitle1">Title</Typography>
            </Grid>
            <Grid item xs={10}>
              <TextField
                fullWidth
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <LoadingButton
            loading={isLoading}
            sx={{ marginTop: "10px" }}
            variant="contained"
            onClick={handleSave}
          >
            Save
          </LoadingButton>
        </Grid>
      </Grid>
    </div>
  );
}

export default CheckoutSettings;
