import {
  URL,
  MERCHANT_ID,
  UPDATE_PROCESSOR_ENDPOINT,
} from "constants/Constants";
import React, { useEffect, useReducer, useState } from "react";
import "./ProcessorSettingCard.css";
import { TextField } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ChipDropdownSelector from "../ChipDropdownSelector/ChipDropdownSelector";
import { httpClient } from "utils/HttpClient";
import Checkbox from "@mui/material/Checkbox";
import ProcessorsAccordion from "./ProcessorAccordin";
import LoadingButton from "@mui/lab/LoadingButton";
import { StyledTableCell, StyledTableRow } from "utils/Styles";
function ProcessorSettingCard({
  index,
  data,
  processorData,
  onSaveClick,
  onCancelClick,
  setIsLoading,
}) {
  console.log(index, data, processorData);
  const [editable, setEditable] = useState(false);
  const [isloadingCancel, setIsLoadingCancel] = useState(false);
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const processorSpecificReducer = (state, action) => {
    switch (action.type) {
      case "INITIALIZE":
        let obj = processorData?.filter((item) => {
          return item.code === data?.processorCode;
        });
        return obj;

      default:
        return state;
    }
  };

  const [processorSpecificData, dispatchProcessorSpecificData] = useReducer(
    processorSpecificReducer,
    []
  );
  let processorDataObj = data || {};

  useEffect(() => {
    dispatchProcessorSpecificData({ type: "INITIALIZE" });
  }, []);

  const handleCredentialsChange = (item, value) => {
    processorDataObj = {
      ...processorDataObj,
      processorConfigurations: {
        ...processorDataObj.processorConfigurations,
        [item]: value,
      },
    };
  };

  const handlePaymentStatusChange = (item) => {
    processorDataObj = {
      ...processorDataObj,
      paymentTypes: {
        ...processorDataObj.paymentTypes,
        [item]: {
          ...processorDataObj.paymentTypes[item],
          status:
            processorDataObj.paymentTypes[item].status === "ACTIVE"
              ? "DISABLED"
              : "ACTIVE",
        },
      },
    };
  };

  const handleDefaultOptionChange = (item) => {
    processorDataObj = {
      ...processorDataObj,
      paymentTypes: {
        ...processorDataObj.paymentTypes,
        [item]: {
          ...processorDataObj.paymentTypes[item],
          defaultOption: !processorDataObj.paymentTypes[item].defaultOption,
        },
      },
    };
  };

  const handleDropdownChange = (currenciesArray, paymentMethod) => {
    processorDataObj = {
      ...processorDataObj,
      paymentTypes: {
        ...processorDataObj.paymentTypes,
        [paymentMethod]: {
          ...processorDataObj.paymentTypes[paymentMethod],
          currencies: currenciesArray,
        },
      },
    };
  };

  const updateApi = () => {
    setIsLoading(true);
    httpClient.fetchData(
      `${URL}${UPDATE_PROCESSOR_ENDPOINT}/${data.id}?merchant_id=${MERCHANT_ID}`,
      "PUT",
      processorDataObj
    ).then((response) => {
      console.log(processorDataObj);
      processorDataObj = response;
      onSaveClick();
    });
  };

  const handleEditSaveClick = (e) => {
    setIsLoadingSave(true);
    if (editable) {
      e.preventDefault();
      setEditable(false);
    } else {
      updateApi();
      setEditable(true);
    }
    setIsLoadingSave(false);
  };

  const handleCancel = () => {
    setIsLoadingCancel(true);
    onCancelClick();
    setIsLoadingCancel(false);
  };

  // const handleDisableClick = () => {
  //   processorDataObj = {
  //     ...processorDataObj,
  //     status: processorDataObj.status === "ACTIVE" ? "DISABLED" : "ACTIVE",
  //   };
  //   updateApi();
  // };

  return (
    <div className="processor-setting-container" key={index}>
      <div className="processor-setting-body">
        <p>
          {data?.processorCode.charAt(0).toUpperCase() +
            data?.processorCode.slice(1).toLowerCase()}{" "}
          Processor Settings
        </p>
        <br></br>
        <p>Integration Steps:</p>
      </div>
      <div className="processor-setting">
        <TableContainer>
          {" "}
          <ProcessorsAccordion processor={data.processorCode} />
        </TableContainer>
      </div>

      <div className="processor-setting-body">
        <div className="processor-table">
          <div className="processor-details">
            <p>Credentials & Properties:</p>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableBody>
                  {Object.keys(data?.processorConfigurations).map(
                    (item, key) => {
                      return (
                        <>
                          <StyledTableRow
                            key={key}
                            style={{
                              border: "1px solid #bbbbbb",
                              borderRadius: "16px",
                            }}
                          >
                            <StyledTableCell>{item}</StyledTableCell>
                            <StyledTableCell>
                              <TextField
                                sx={{ width: 500 }}
                                className="processor-details-input"
                                defaultValue={
                                  processorDataObj?.processorConfigurations[
                                    item
                                  ]
                                }
                                onChange={(e) =>
                                  handleCredentialsChange(item, e.target.value)
                                }
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                        </>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <p>Payment Methods:</p>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Payment Methods</StyledTableCell>
                  <StyledTableCell>Currencies</StyledTableCell>
                  <StyledTableCell>Enable</StyledTableCell>
                  <StyledTableCell>Default Option</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(data?.paymentTypes).map((item, key) => {
                  return (
                    <StyledTableRow key={key}>
                      <StyledTableCell>{item}</StyledTableCell>
                      <StyledTableCell>
                        {processorSpecificData && !editable && (
                          <ChipDropdownSelector
                            currencies={data?.paymentTypes[item]}
                            processordata={processorSpecificData}
                            paymentMethod={item}
                            onChangeCallback={handleDropdownChange}
                          />
                        )}
                        <p className="paymnent-currency-list">
                          [
                          {processorDataObj?.paymentTypes[
                            item
                          ]?.currencies.join(",")}
                          ]
                        </p>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Checkbox
                          disabled={editable}
                          defaultChecked={
                            processorDataObj?.paymentTypes[item]?.status ===
                            "ACTIVE"
                              ? true
                              : false
                          }
                          value={processorDataObj?.paymentTypes[item]?.status}
                          onChange={(e) => {
                            handlePaymentStatusChange(item);
                          }}
                        ></Checkbox>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Checkbox
                          disabled={editable}
                          value={
                            processorDataObj?.paymentTypes[item]?.defaultOption
                          }
                          defaultChecked={
                            processorDataObj?.paymentTypes[item]?.defaultOption
                          }
                          onChange={() => handleDefaultOptionChange(item)}
                        ></Checkbox>
                        {data?.paymentTypes[item]?.defaultOption}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="processor-settings-buttons">
          <LoadingButton
            loading={isloadingCancel}
            variant="contained"
            sx={{ marginLeft: "auto" }}
            onClick={(e) => handleCancel()}
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            loading={isLoadingSave}
            variant="contained"
            sx={{ marginLeft: "auto" }}
            onClick={(e) => handleEditSaveClick(e)}
          >
            {!editable ? "Save" : "Edit"}
          </LoadingButton>

          {/* <Button
              variant="contained"
              color={`${data?.status === "ACTIVE" ? "warning" : "success"}`}
              onClick={() => handleDisableClick()}
            >
              {data?.status === STATUS_ACTIVE ? "Disable" : "Enable"}
            </Button> */}
        </div>
      </div>
    </div>
  );
}

export default ProcessorSettingCard;
