import App from "../App";
import React from "react";
import ReactDOM from "react-dom/client";
// httpClient.js
class KC {
    constructor() {
      this.defaults = {
        kc: null,
      };
    }
  
    setKC(kc) {
      this.defaults.kc = kc;
    }
  
    getKC() {
      return this.defaults.kc;
    }

    logout() {
        if (this.defaults.kc) {
            this.defaults.kc.logout();            
        }
        const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);
    }
}
  const KCClient = new KC();
  
  export { KCClient };
  