import React, { useEffect, useState } from "react";
import "./RulesSettingCard.css";
import RulesCondition from "../RulesCondition/RulesCondition";
import { MenuItem, FormControl, TextField, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import Plus from "../../Icons/Plus";
import Tree from "../../Icons/Tree";
import { httpClient } from "utils/HttpClient";
import { URL, RULES_ENDPOINT } from "constants/Constants";
import { processors } from "utils/processors";
import { cloneDeep, isArray } from "lodash";

function RulesSettingCard(
  { data, onDataDeleteChange, index, fetchData, setIsLoading },
  props
) {
  const [ruleData, setRuleData] = useState({});
  const [showAddRule, setShowAddRule] = useState(false);
  const [error, setError] = useState({ nameError: "", resultError: "" });
  const [conditionError, setConditionError] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [breadcrumbList, setBreadcrumbList] = React.useState([
    { title: "Rules", component: <Tree /> },
  ]);
  useEffect(() => {
    setRuleData({
      ...data,
    });
  }, [data]);

  const recurse = (obj, value) => {
    for (let i = 0; i < obj?.length; i++) {
      if (obj[i].hasOwnProperty("nestedOperation")) {
        recurse(obj[i].conditions, value);
      } else {
        if (value.id === obj[i].id) {
          obj[i] = value;
        }
      }
    }
  };

  const handleEditSaveClick = (obj) => {
    if (!isEditing) {
      const updatedObj = cloneDeep(obj);
      setIsEditing(true);
      if (
        updatedObj.condition.conditions &&
        isArray(updatedObj.condition.conditions)
      )
        setRuleData({ ...updatedObj });
      else
        setRuleData({
          ...updatedObj,
          condition: {
            nestedOperation: "AND",
            conditions: [updatedObj.condition],
          },
        });
      return;
    }
    setIsLoading(true);
    const validRule = cloneDeep(ruleData);
    if (!validateAndUpdateConditions(validRule.condition)) {
      setConditionError(true);
      setRuleData({ ...validRule });
      setIsLoading(false);
    }
    if (validateAndUpdateConditions(validRule.condition)) {
      let finalObject = cloneDeep(validRule);
      deleteProperties(finalObject);
      console.log(finalObject, ruleData);
      if (finalObject?.condition?.conditions?.length === 1) {
        const updatedCondition = finalObject.condition.conditions[0];
        finalObject = {
          ...finalObject,
          condition: { ...updatedCondition },
        };
      }
      httpClient
        .fetchData(
          `${URL}${RULES_ENDPOINT}/${ruleData?.id}`,
          "PUT",
          finalObject
        )
        .then((res) => {
          console.log("Rule updated:", res);
          fetchData();
          setIsEditing(false);
        })
        .catch((error) => {
          console.error("Error in updating rule:", error);
        });
    }
  };
  const handleAddClick = () => {
    setShowAddRule(true);
    setIsEditing(true);
    setRuleData({
      id: new Date().getTime(),
      name: "",
      result: "",
      status: "ACTIVE",
      condition: {
        nestedOperation: "AND",
        conditions: [
          {
            id: new Date().getTime(),
            param: undefined,
            operation: undefined,
            value: undefined,
            isError: false,
            errorMessage: "",
          },
        ],
      },
    });
  };
  const handleCancelClick = () => {
    setShowAddRule(false);
    setIsEditing(false);
  };

  function deleteProperties(obj) {
    // Define the properties to delete
    const propertiesToDelete = new Set(["isError", "errorMessage"]);

    // Recursive function to traverse and delete properties
    function traverseAndDelete(currentObj) {
      if (Array.isArray(currentObj)) {
        // If it's an array, loop through its elements
        currentObj.forEach((item) => traverseAndDelete(item));
      } else if (currentObj !== null && typeof currentObj === "object") {
        // If it's an object, loop through its properties
        Object.keys(currentObj).forEach((key) => {
          if (propertiesToDelete.has(key)) {
            // Delete the property if it's in the list
            delete currentObj[key];
          } else if (key === "id" && typeof currentObj[key] === "number") {
            // Delete the 'id' property only if its value is a number
            delete currentObj[key];
          } else {
            // Recursively call the function for nested objects
            traverseAndDelete(currentObj[key]);
          }
        });
      }
    }
    // Start the recursive deletion
    traverseAndDelete(obj);
  }

  const checkIfValidNameAndResult = (ruleData) => {
    const regexPattern = /^[A-Za-z0-9_-]+$/;
    if (ruleData.name.length === 0 && ruleData.result.length === 0) {
      setError({
        nameError: "Rule name should not be empty!",
        resultError: "Processor should not be empty!",
      });
      return false;
    } else if (
      !regexPattern.test(ruleData.name) &&
      ruleData.result.length === 0
    ) {
      setError({
        nameError:
          "Rule name should not contain special characters except _ & -!",
        resultError: "Processor should not be empty!",
      });
      return false;
    } else if (ruleData.name.length === 0) {
      setError({ ...error, nameError: "Rule name should not be empty!" });
      return false;
    } else if (!regexPattern.test(ruleData.name)) {
      setError({
        ...error,
        nameError:
          "Rule name should not contain special characters except _ & -!",
      });
      return false;
    } else if (ruleData.result.length === 0) {
      setError({
        nameError: "",
        resultError: "Processor should not be empty!",
      });
      return false;
    } else {
      return true;
    }
  };
  function validateAndUpdateConditions(condition) {
    // Helper function to check if a string is empty
    const isEmpty = (str) => !str || str.trim() === "";

    // Variable to keep track of the validation status
    let noErrors = true;

    // Recursive function to validate and update each condition
    const validateAndUpdate = (cond) => {
      if (cond.conditions && Array.isArray(cond.conditions)) {
        // If there are nested conditions, validate and update each one recursively
        cond.conditions.forEach(validateAndUpdate);
      } else {
        // Initialize errorMessage as an empty array
        cond.errorMessage = [];

        // Check for empty fields and set isError flag and errorMessage accordingly
        if (isEmpty(cond.param)) {
          cond.isError = true;
          cond.errorMessage.push("Param cannot be empty");
          noErrors = false;
        }
        if (isEmpty(cond.operation)) {
          cond.isError = true;
          cond.errorMessage.push("Operation cannot be empty");
          noErrors = false;
        }
        if (isEmpty(cond.value)) {
          cond.isError = true;
          cond.errorMessage.push("Value cannot be empty");
          noErrors = false;
        }

        // Join error messages if there are multiple
        if (cond.errorMessage.length > 0) {
          cond.errorMessage = cond.errorMessage.join("; ");
        } else {
          delete cond.errorMessage; // Remove errorMessage if there are no errors
        }
      }
    };

    // Start the validation and update process
    validateAndUpdate(condition);

    // Return true if no errors were found, false otherwise
    return noErrors;
  }

  const handleAddRule = () => {
    setIsLoading(true);
    const validRule = cloneDeep(ruleData);
    if (!validateAndUpdateConditions(validRule.condition)) {
      setConditionError(true);
      setRuleData({ ...validRule });
      setIsLoading(false);
    }
    if (
      checkIfValidNameAndResult(ruleData) &&
      validateAndUpdateConditions(validRule.condition)
    ) {
      let finalObject = cloneDeep(validRule);
      deleteProperties(finalObject);
      if (finalObject?.condition?.conditions?.length === 1) {
        const updatedCondition = finalObject.condition.conditions[0];
        finalObject = {
          ...finalObject,
          condition: { ...updatedCondition },
        };
      }
      setShowAddRule(false);
      setIsEditing(false);
      httpClient
        .fetchData(`${URL}${RULES_ENDPOINT}`, "POST", {
          id: null,
          ...finalObject,
        })
        .then((res) => {
          console.log("New rule added:", res);
          fetchData();
        })
        .catch((error) => {
          console.error("Error adding new rule:", error);
          setIsLoading(false);
        });
    }
  };

  const handleDeleteClick = () => {
    setIsLoading(true);
    httpClient
      .fetchData(`${URL}${RULES_ENDPOINT}/${ruleData.id}`, "DELETE")
      .then((res) => {
        console.log("Deleted rule:", res);
        fetchData();
      })
      .catch((error) => {
        console.error("Error deleting rule:", error);
        setIsLoading(false);
      });
  };

  const handleEnableDisableClick = (status) => {
    setIsLoading(true);
    httpClient
      .fetchData(`${URL}${RULES_ENDPOINT}/${ruleData.id}`, "PATCH", {
        status: status === "ACTIVE" ? "DISABLED" : "ACTIVE",
      })
      .then((res) => {
        console.log("Updated rule status:", res);
        fetchData();
      })
      .catch((error) => {
        console.error("Error updating rule status:", error);
        setIsLoading(false);
      });
  };

  const handleDuplicateCall = (obj) => {
    const updatedObj = cloneDeep(obj);
    setShowAddRule(true);
    setIsEditing(true);
    const updatedName = `${updatedObj.name}_COPY`;
    if (
      updatedObj.condition.conditions &&
      isArray(updatedObj.condition.conditions)
    )
      setRuleData({
        ...updatedObj,
        id: new Date().getTime(),
        name: updatedName,
      });
    else
      setRuleData({
        id: new Date().getTime(),
        name: updatedName,
        result: updatedObj.result,
        condition: {
          nestedOperation: "AND",
          conditions: [updatedObj.condition],
        },
      });
  };

  const handleRuleNameChange = (event) => {
    setRuleData({
      ...ruleData,
      name: event.target.value,
    });
    setError({ ...error, nameError: "" });
  };

  const handleProcessorChange = (event) => {
    setRuleData({
      ...ruleData,
      result: event.target.value,
    });
    setError({ ...error, resultError: "" });
  };

  const Header = () => {
    return (
      <>
        <div className="header-container">
          <div className="header-title-container">
            <div className="header-title">Rules</div>
            <div className="header-sub-title">
              Find the list of configured rules and user can also add new rule.
            </div>
          </div>

          <Tooltip title="Add New Rule">
            <div className={`header-action`} onClick={handleAddClick}>
              <Plus className="header-action-img" />
              <div className="header-action-title">
                New Rule
                {/* {Object.keys(data)?.length <= 0 ? "Add Rule" : "Add New Rule"} */}
              </div>
            </div>
          </Tooltip>
        </div>
      </>
    );
  };

  return (
    <div className="rules-setting-container " key={ruleData.id}>
      <div
        className="rules-add-body"
        style={{ display: "flex", flexDirection: "column" }}
      >
        {index === 0 && (
          <>
            <div className="breadcrumb-container">
              <Breadcrumb list={breadcrumbList} />
            </div>
            <Header />
          </>
        )}

        {showAddRule && (
          <div className="rule-container">
            <table sx={{ minWidth: 700, alignItems: "center" }}>
              <tablebody>
                <tr sx={{ marginTop: "15px", marginBottom: "48px" }}>
                  <td>Rule</td>
                  <td>
                    {" "}
                    <FormControl fullWidth>
                      <TextField
                        label="Rule"
                        variant="outlined"
                        onChange={handleRuleNameChange}
                        value={ruleData?.name}
                        key={index}
                        error={error.nameError.length > 0}
                        helperText={error.nameError}
                        sx={{
                          width: "100%",
                          "& .MuiOutlinedInput-root": {
                            height: "40px",
                            "& input": {
                              height: "40px",
                              padding: "0 14px",
                            },
                          },
                          "& .MuiInputLabel-root": {
                            marginTop: "-0.5rem",
                            "&.Mui-focused": {
                              marginTop: "0px",
                            },
                          },
                        }}
                      />
                    </FormControl>
                  </td>
                </tr>
                <tr sx={{ marginTop: "15px", marginBottom: "48px" }}>
                  <td>Processor</td>
                  <td>
                    {" "}
                    <FormControl fullWidth>
                      <TextField
                        labelId="processor-select-label"
                        id="processor-select"
                        value={ruleData.result}
                        onChange={handleProcessorChange}
                        label={"Processor"}
                        key={index}
                        select
                        error={error.resultError.length > 0}
                        helperText={error.resultError}
                        sx={{
                          width: "100%",
                          "& .MuiOutlinedInput-root": {
                            height: "40px",
                            "& input": {
                              height: "40px",
                              padding: "0 14px",
                            },
                          },
                          "& .MuiInputLabel-root": {
                            marginTop: "-0.5rem",
                            "&.Mui-focused": {
                              marginTop: "0px",
                            },
                          },
                        }}
                      >
                        {processors.map(
                          (processor) =>
                            processor.displayName !== "default" && (
                              <MenuItem
                                key={processor.displayName}
                                value={processor.processorCode}
                              >
                                {processor.displayName}
                              </MenuItem>
                            )
                        )}
                      </TextField>
                    </FormControl>
                  </td>
                </tr>
                <tr>
                  <td>Conditions</td>
                  <td>
                    {" "}
                    {ruleData.condition?.conditions?.length > 0 ? (
                      <RulesCondition
                        data={{ condition: ruleData.condition }}
                        level={0}
                        setRuleData={setRuleData}
                        ruleData={ruleData}
                        indexPath={[]}
                        conditionError={conditionError}
                        key={ruleData.id}
                        setConditionError={setConditionError}
                        ruleId={ruleData.id}
                        isEditing={isEditing}
                      />
                    ) : (
                      <RulesCondition
                        data={{
                          condition: {
                            nestedOperation: "AND",
                            conditions: [
                              ruleData?.condition?.conditions[0] || {
                                id: new Date().getTime(),
                                param: undefined,
                                operation: undefined,
                                value: undefined,
                                isError: false,
                                errorMessage: "",
                              },
                            ],
                          },
                        }}
                        level={0}
                        setRuleData={setRuleData}
                        ruleData={ruleData}
                        indexPath={[]}
                        conditionError={conditionError}
                        key={ruleData.id}
                        setConditionError={setConditionError}
                        ruleId={ruleData.id}
                        isEditing={isEditing}
                      />
                    )}
                  </td>
                </tr>
              </tablebody>
            </table>

            <div className="processor-settings-buttons">
              <button onClick={handleCancelClick}>Cancel</button>
              <button onClick={handleAddRule}>Add</button>
            </div>
          </div>
        )}
      </div>

      {Object.keys(data)?.length !== 0 && (
        <div className="rules-setting-body" key={props.key}>
          <Typography className="rule-name">Rule : {data?.name}</Typography>
          <span className="processor-name">
            Processor: <span className="processor-value">{data?.result}</span>
          </span>

          <div>
            <RulesCondition
              data={{
                condition: isArray(data?.condition?.conditions)
                  ? data.condition
                  : {
                      nestedOperation: "AND",
                      conditions: [data.condition],
                    },
              }}
              level={0}
              setRuleData={setRuleData}
              ruleData={ruleData}
              indexPath={[]}
              conditionError={conditionError}
              setConditionError={setConditionError}
              key={data.id}
              ruleId={data.id}
              isEditing={isEditing}
            />
            <div className="rules-settings-buttons">
              <button
                onClick={() => handleEditSaveClick(data)}
                disabled={data?.status === "DISABLED"}
              >
                {isEditing ? "Save" : "Edit"}
              </button>
              <button onClick={() => handleEnableDisableClick(data.status)}>
                {data.status === "ACTIVE" ? "Disable" : "Enable"}
              </button>
              <button onClick={() => handleDuplicateCall(data)}>
                Duplicate
              </button>
              <button onClick={() => handleDeleteClick()}>Delete</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default RulesSettingCard;
