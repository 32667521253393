import React from 'react';

const Close = () => (<svg
  xmlns="https://www.w3.org/2000/svg"
  width="20"
  height="21"
  viewBox="0 0 20 21"
  fill="none"
>
  <path
    d="M10.0001 9.35087L13.7126 5.63837L14.7731 6.69887L11.0606 10.4114L14.7731 14.1239L13.7126 15.1844L10.0001 11.4719L6.28755 15.1844L5.22705 14.1239L8.93955 10.4114L5.22705 6.69887L6.28755 5.63837L10.0001 9.35087Z"
    fill="#525866"
  />
</svg>);

export default Close;