import RulesSettingCard from "components/Elements/RulesSettingCard/RulesSettingCard";
import { URL, MERCHANT_ID, RULES_ENDPOINT } from "constants/Constants";
import React, { useEffect, useState } from "react";
import { httpClient } from "utils/HttpClient";

function Rules() {
  const [rules, setRules] = useState([]);
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    fetchTransactionData();
  }, []);

  const fetchTransactionData = () => {
    //console.log("raiden-33", data);
    setIsLoading(true);
    httpClient
      .fetchData(`${URL}${RULES_ENDPOINT}?merchant_id=${MERCHANT_ID}`, "GET")
      .then((data) => {
        console.log(data);
        setRules(data);
        setData(data);
        setIsLoading(false);
        // setTableHeadings(Object.keys(data[0]));
      });
  };
  const onDataDeleteChange = (updatedData, idx) => {
    let ob = data || [];
    ob[idx] = updatedData;
    setRules((prev) => [...ob]);
  };

  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "200px",
          }}
        >
          <div className="spinner"></div>
        </div>
      ) : (
        <div>
          {(rules || []).length === 0 ? (
            <RulesSettingCard
              index={0}
              data={{}}
              onDataDeleteChange={onDataDeleteChange}
              fetchData={fetchTransactionData}
              setIsLoading={setIsLoading}
            />
          ) : (
            rules?.map((item, index) => (
              <RulesSettingCard
                key={index}
                index={index}
                data={item}
                onDataDeleteChange={onDataDeleteChange}
                fetchData={fetchTransactionData}
                setIsLoading={setIsLoading}
              />
            ))
          )}
        </div>
      )}
    </>
  );
}

export default Rules;
