import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Close from "../Icons/Close";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import "./StepsToIntegrateModal.css";
import { Divider } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { styled } from "@mui/material/styles";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 616,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "20px",
  maxHeight: "80vh",
  overflowY: "auto",
};

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  height: "32px",
  padding: "8px 12px 8px 12px",
  gap: "6px",
  borderRadius: "6px 0px 0px 0px",
  opacity: "0px",
  backgroundColor: "var(--bg-weak-50, #f5f7fa)",
  width: "584px",
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Inter",
  fontSize: "12px",
  fontWeight: "500",
  lineHeight: "16px",
  letterSpacing: "0.04em",
  textAlign: "left",
  color: "var(--text-sub-600, #525866)",
}));

const StepsToIntegrateModal = ({ open, setOpen }) => {
  return (
    <Modal open={open} onClose={() => setOpen(false)} sx={{ width: "auto" }}>
      <Box sx={style}>
        <div className="modal-header">
          <span className="modal-header-container">
            <HelpOutlineOutlinedIcon />
            <div className="modal-header-title-container">
              <div className="modal-header-title">Steps to Integrate</div>
            </div>
          </span>
          <span className="modal-header-close" onClick={() => setOpen(false)}>
            <Close />
          </span>
        </div>
        <Divider />
        <Box sx={{ margin: "16px" }}>
          <Accordion sx={{ marginBottom: "16px" }}>
            <StyledAccordionSummary
              aria-controls="panel1-content"
              id="panel1-header"
              expandIcon={<AddCircleOutlineIcon />}
            >
              <StyledTypography>FETCH API KEYS</StyledTypography>
            </StyledAccordionSummary>
            <AccordionDetails>
              <Typography>
                1. Login to Midtrans Dashboard
                (https://dashboard.sandbox.midtrans.com/)
              </Typography>
              <Typography>
                2. Navigate to Access Keys under Settings section from left side
                menu.
              </Typography>
              <Typography>
                3. Copy the Merchant ID, Client Key and Secret Key and configure
                Payorch Midtrans API credentials block.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ marginBottom: "16px" }}>
            <StyledAccordionSummary
              aria-controls="panel2-content"
              id="panel2-header"
              expandIcon={<AddCircleOutlineIcon />}
            >
              <StyledTypography>SETUP WEBHOOKS</StyledTypography>
            </StyledAccordionSummary>
            <AccordionDetails>
              <Typography>
                1. Login to Midtrans Dashboard
                (https://dashboard.sandbox.midtrans.com/)
              </Typography>
              <Typography>
                2. Navigate to Payment section under Settings section from left
                side menu.
              </Typography>
              <Typography>
                {"3. Click on Payment Settings -> Notification URL"}
              </Typography>
              <Typography>
                4. Configure the following webhook URL, for Payment Notification
                URL
                https://api.prtest.payorch.com/midtrans/8988d0e0-cf0e-4e11-a83a-3d689239d0e3/webhooks
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ marginBottom: "16px" }}>
            <StyledAccordionSummary
              aria-controls="panel2-content"
              id="panel2-header"
              expandIcon={<AddCircleOutlineIcon />}
            >
              <StyledTypography>SETUP RETURN URLS</StyledTypography>
            </StyledAccordionSummary>
            <AccordionDetails sx={{ margin: "16px" }}>
              <Typography>
                1. Login to Midtrans Dashboard
                (https://dashboard.sandbox.midtrans.com/)
              </Typography>
              <Typography>
                2. Navigate to Payment section under Settings section from the
                left side menu.
              </Typography>
              <Typography>
                {"3. Click on Payment Settings -> Finish Redirect URL"}
              </Typography>
              <Typography>
                4. Configure the following redirect URL for Finish Redirect URL:
                https://api.prtest.payorch.com/return/midtrans?merchantid=8988d0e0-cf0e-4e11-a83a-3d689239d0e3
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </Modal>
  );
};

export default StepsToIntegrateModal;
