import React, { useState, useLayoutEffect } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Paper,
  Button,
  Alert,
} from "@mui/material";
import CopyIcon from "@mui/icons-material/FileCopy";
import RefreshIcon from "@mui/icons-material/Refresh";
import { DNA } from "react-loader-spinner";
import {
  URL,
  API_ENDPOINT,
  MERCHANT_ID,
  API_REGENERATE_ENDPOINT,
} from "constants/Constants";
import { httpClient } from "utils/HttpClient";
import { StyledTableCell, StyledTableRow } from "utils/Styles";
function ApiCredentials() {
  const [data, setData] = useState({});
  const [isGenerated, setIsGenerated] = useState({
    public: false,
    private: false,
  });
  const [isLoading, setIsLoading] = useState(true);
  useLayoutEffect(() => {
    fetchApiCredentialsData();
  }, []);

  const fetchApiCredentialsData = async () => {
    httpClient
      .fetchData(`${URL}${API_ENDPOINT}?merchant_id=${MERCHANT_ID}`, "GET")
      .then((data) => {
        console.log(data);
        setData(data);
        setIsLoading(false);
      });
  };

  const handleAction = async (type, action) => {
    const Type = type.toLowerCase();

    console.log(`Action: ${action} for ${type}`);

    if (action === "regenerate") {
      httpClient
        .fetchData(
          `${URL}${API_REGENERATE_ENDPOINT}?merchant_id=${MERCHANT_ID}&type=${Type}`,
          "GET"
        )
        .then((responseData) => {
          setIsGenerated((prev) => ({ ...prev, [Type]: true }));
          setData((prevData) => ({
            ...prevData,
            [Type]: responseData && responseData[Type],
          }));
        });
    }
  };

  const renderTableRow = (data) => (
    <>
      <StyledTableRow>
        <StyledTableCell>Public</StyledTableCell>
        <StyledTableCell>
          {data?.public && data?.public?.length <= 6 && "****************"}
          {data.public ? data.public : "Not Generated"}
        </StyledTableCell>
        <StyledTableCell sx={{ display: "flex", gap: "30px" }}>
          {isGenerated["public"] && (
            <Button
              onClick={() => handleAction("public", "copy")}
              startIcon={<CopyIcon />}
            >
              Copy
            </Button>
          )}
          <Button
            onClick={() => handleAction("public", "regenerate")}
            startIcon={<RefreshIcon />}
          >
            {data.public ? "Regenerate" : "Generate"}
          </Button>
        </StyledTableCell>
      </StyledTableRow>
      <StyledTableRow>
        <StyledTableCell colSpan={3} sx={{ padding: "0px" }}>
          {isGenerated["public"] && (
            <Alert
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              icon={false}
              severity="info"
            >
              Please copy and save the key safely now itself, as it won't appear
              again.
            </Alert>
          )}
        </StyledTableCell>
      </StyledTableRow>
      <StyledTableRow>
        <StyledTableCell>Private</StyledTableCell>
        <StyledTableCell>
          {data?.private && data?.private?.length <= 6 && "****************"}
          {data?.private ? data?.private : "Not Generated"}
        </StyledTableCell>
        <StyledTableCell sx={{ display: "flex", gap: "30px" }}>
          {isGenerated["private"] && (
            <Button
              onClick={() => handleAction("private", "copy")}
              startIcon={<CopyIcon />}
            >
              Copy
            </Button>
          )}
          <Button
            onClick={() => handleAction("private", "regenerate")}
            startIcon={<RefreshIcon />}
          >
            {data?.private ? "Regenerate" : "Generate"}
          </Button>
        </StyledTableCell>
      </StyledTableRow>
      <StyledTableRow>
        <StyledTableCell colSpan={3} sx={{ padding: "0px" }}>
          {isGenerated["private"] && (
            <Alert
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              icon={false}
              severity="info"
            >
              Please copy and save the key safely now itself, as it won't appear
              again.
            </Alert>
          )}
        </StyledTableCell>
      </StyledTableRow>
    </>
  );

  return (
    <div>
      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Token</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          {isLoading ? (
            <TableBody>
              <tr>
                <td colSpan={3} style={{ textAlign: "center" }}>
                  <DNA
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="dna-loading"
                    wrapperStyle={{}}
                    wrapperClass="dna-wrapper"
                  />
                </td>
              </tr>
            </TableBody>
          ) : (
            <TableBody>{renderTableRow(data)}</TableBody>
          )}
        </Table>
      </TableContainer>
    </div>
  );
}

export default ApiCredentials;
