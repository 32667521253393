import * as React from "react";
import "./Breadcrumb.css";

const Breadcrumb = ({ list, onClick }) => {
  return list.map((item) => (
    <>
      <div className="breadcrumb-item">
        {item.src && (
          <img
            loading="lazy"
            src={item.src}
            className="breadcrumb-img"
            onClick={onClick}
          />
        )}
        {item.component && (
          <div className="breadcrumb-component" onClick={onClick}>
            {item.component}
          </div>
        )}
        <div className="breadcrumb-title" onClick={onClick}>
          {item.title}
        </div>
      </div>
    </>
  ));
};

export default Breadcrumb;
