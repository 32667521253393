import React from "react";

const Group = () => (
  <svg
    xmlns="https://www.w3.org/2000/svg"
    width="17"
    height="40"
    viewBox="0 0 17 40"
    fill="none"
  >
    <rect width="17" height="40" rx="4" fill="#99A0AE" />
    <path
      d="M6 12L11 12"
      stroke="#E6E6EB"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6 20L11 20"
      stroke="#E6E6EB"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6 28L11 28"
      stroke="#E6E6EB"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default Group;
