import React, { useEffect, useReducer, useState } from "react";
import "./ProcessorSettingCard.css";
import { TextField } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ChipDropdownSelector from "../ChipDropdownSelector/ChipDropdownSelector";
import { httpClient } from "utils/HttpClient";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import ProcessorsAccordion from "./ProcessorAccordin";
import { StyledTableCell, StyledTableRow } from "utils/Styles";

import {
  URL,
  UPDATE_PROCESSOR_ENDPOINT,
  MERCHANT_ID,
} from "constants/Constants";
function NewProcessorSettingCard({
  index,
  data,
  processorData,
  onSaveClick,
  onCancelClick,
  setIsLoading,
}) {
  console.log(index, data, processorData);
  const [editable, setEditable] = useState(false);
  const processorSpecificReducer = (state, action) => {
    switch (action.type) {
      case "INITIALIZE":
        let obj = processorData?.filter((item) => {
          return item.code === data?.code;
        });
        return obj;

      default:
        return state;
    }
  };

  const [processorSpecificData, dispatchProcessorSpecificData] = useReducer(
    processorSpecificReducer,
    []
  );
  let processorDataObj = {
    processorCode: data?.code,
    status: "ACTIVE",
    processorConfigurations: {},
    paymentTypes: {},
  };
  for (const item in data?.paymentMethods) {
    if (Object.prototype.hasOwnProperty.call(data?.paymentMethods, item)) {
      processorDataObj.paymentTypes[item] = {
        defaultOption: false,
        status: "DISABLED",
        currencies: data?.paymentMethods[item].currencies,
      };
    }
  }
  useEffect(() => {
    dispatchProcessorSpecificData({ type: "INITIALIZE" });
  }, []);

  const handleCredentialsChange = (item, value) => {
    processorDataObj = {
      ...processorDataObj,
      processorConfigurations: {
        ...processorDataObj.processorConfigurations,
        [item]: value,
      },
    };
  };

  const handlePaymentStatusChange = (item) => {
    console.log(processorDataObj);
    if (!processorDataObj.paymentTypes.hasOwnProperty(item)) {
      processorDataObj = {
        ...processorDataObj,
        paymentTypes: {
          ...processorDataObj.paymentTypes,
          [item]: {
            status: "ACTIVE",
          },
        },
      };
    } else {
      processorDataObj = {
        ...processorDataObj,
        paymentTypes: {
          ...processorDataObj.paymentTypes,
          [item]: {
            ...processorDataObj.paymentTypes[item],
            status:
              processorDataObj.paymentTypes[item].status === "ACTIVE"
                ? "DISABLED"
                : "ACTIVE",
          },
        },
      };
    }
    console.log(processorDataObj);
  };

  const handleDefaultOptionChange = (item) => {
    processorDataObj = {
      ...processorDataObj,
      paymentTypes: {
        ...processorDataObj.paymentTypes,
        [item]: {
          ...processorDataObj.paymentTypes?.[item],
          defaultOption:
            !processorDataObj.paymentTypes?.[item]?.defaultOption ?? true,
        },
      },
    };

    console.log(processorDataObj);
  };

  const handleDropdownChange = (currenciesArray, paymentMethod) => {
    if (!processorDataObj.paymentTypes.hasOwnProperty(paymentMethod)) {
      processorDataObj = {
        ...processorDataObj,
        paymentTypes: {
          ...processorDataObj.paymentTypes,
          [paymentMethod]: {
            currencies: currenciesArray,
          },
        },
      };
    } else {
      processorDataObj = {
        ...processorDataObj,
        paymentTypes: {
          ...processorDataObj.paymentTypes,
          [paymentMethod]: {
            ...processorDataObj.paymentTypes[paymentMethod],
            currencies: currenciesArray,
          },
        },
      };
    }
  };

  const updateApi = () => {
    setIsLoading(true);
    httpClient.fetchData(
      `${URL}${UPDATE_PROCESSOR_ENDPOINT}?merchant_id=${MERCHANT_ID}`,
      "POST",
      processorDataObj
    )
      .then((response) => {
        console.log(processorDataObj);
        processorDataObj = response;
        onSaveClick();
      })
      .catch((error) => {
        console.error("Error updating :", error);
        onSaveClick();
      });
  };

  const handleEditSaveClick = (e) => {
    if (editable) {
      e.preventDefault();
      setEditable(false);
    } else {
      updateApi();
      setEditable(true);
    }
  };

  const handleCancel = () => {
    onCancelClick();
  };

  return (
    <div className="processor-setting-container" key={index}>
      <div className="processor-setting-body">
        <p>
          {data?.code.charAt(0).toUpperCase() +
            data?.code.slice(1).toLowerCase()}{" "}
          Processor Settings
        </p>
        <br></br>
        <p>Integration Steps:</p>
      </div>
      <div className="processor-setting">
        <TableContainer>
          {" "}
          <ProcessorsAccordion processor={data.code} />
        </TableContainer>
      </div>

      <div className="processor-setting-body">
        <div className="processor-table">
          <div className="processor-details">
            <p>Credentials & Properties:</p>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableBody>
                  {Object.keys(data?.merchantProperties).map((item, key) => {
                    return (
                      <>
                        <StyledTableRow
                          key={key}
                          style={{
                            border: "1px solid #bbbbbb",
                            borderRadius: "16px",
                          }}
                        >
                          <StyledTableCell>
                            {data?.merchantProperties[item].displayName}
                          </StyledTableCell>
                          <StyledTableCell>
                            <TextField
                              sx={{ width: 500 }}
                              className="processor-details-input"
                              disabled={editable}
                              onChange={(e) =>
                                handleCredentialsChange(
                                  data?.merchantProperties[item].name,
                                  e.target.value
                                )
                              }
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <p>Payment Methods:</p>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Payment Methods</StyledTableCell>
                  <StyledTableCell>Currencies</StyledTableCell>
                  <StyledTableCell>Enable</StyledTableCell>
                  <StyledTableCell>Default Option</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(data?.paymentMethods).map((item, key) => {
                  return (
                    <StyledTableRow key={key}>
                      <StyledTableCell>{item}</StyledTableCell>
                      <StyledTableCell>
                        {processorSpecificData && !editable && (
                          <ChipDropdownSelector
                            currencies={data?.paymentMethods[item]}
                            processordata={processorSpecificData}
                            paymentMethod={item}
                            onChangeCallback={handleDropdownChange}
                          />
                        )}
                        <p className="paymnent-currency-list">
                          [{data?.paymentMethods[item]?.currencies.join(",")}]
                        </p>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Checkbox
                          disabled={editable}
                          defaultChecked={
                            data?.paymentMethods[item]?.status === "ACTIVE"
                          }
                          value={data?.paymentMethods[item]?.status}
                          onChange={(e) => {
                            handlePaymentStatusChange(item);
                          }}
                        ></Checkbox>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Checkbox
                          disabled={editable}
                          value={data?.paymentMethods[item]?.defaultOption}
                          defaultChecked={
                            data?.paymentMethods[item]?.defaultOption
                          }
                          onChange={() => handleDefaultOptionChange(item)}
                        ></Checkbox>
                        {data?.paymentMethods[item]?.defaultOption}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="processor-settings-buttons">
          <Button
            variant="contained"
            sx={{ marginLeft: "auto" }}
            onClick={(e) => handleCancel()}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ marginLeft: "auto" }}
            onClick={(e) => handleEditSaveClick(e)}
          >
            {!editable ? "Save" : "Edit"}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default NewProcessorSettingCard;
