import React, { useState, useEffect } from "react";
import {
  Checkbox,
  TextField,
  Grid,
  Typography,
  FormControlLabel,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import "./Webhooks.css";
import webhookEvents from "../../../utils/WebhookEvents";
import { httpClient } from "utils/HttpClient";
import {
  URL,
  WEBHOOK_SETTINGS_ENDPOINT,
  MERCHANT_ID,
} from "constants/Constants";
import Logout from "Logout";
function Webhooks() {
  const [webhookUrl, setWebhookUrl] = useState("");
  const [webhookProperties, setWebhookProperties] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    fetchWebhookEventsData();
  }, []);

  const fetchWebhookEventsData = async () => {
    try {
      const data = await httpClient.fetchData(
        `${URL}${WEBHOOK_SETTINGS_ENDPOINT}?merchant_id=${MERCHANT_ID}`,
        "GET"
      );
      console.log(data);
      setWebhookUrl(data?.webhookUrl);
      setWebhookProperties(data?.webhookProperties || {});
    } catch (error) {
      console.error("Error fetching webhook data:", error);
    }
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);
      const response = await httpClient.fetchData(
        `${URL}${WEBHOOK_SETTINGS_ENDPOINT}?merchant_id=${MERCHANT_ID}`,
        "POST",
        {
          webhookUrl,
          webhookProperties,
        }
      );
      if (response.ok) {
        return response.json();
      } else {
        // Check if the status code is 401
        if (response.status === 401) {
          Logout();
        } else {
          console.log("Failed saving data:", response.status);
        }
      }
    } catch (error) {
      console.error("Error saving webhook data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (eventType, category) => {
    setWebhookProperties((prevProperties) => ({
      ...prevProperties,
      [eventType]: !prevProperties[eventType],
    }));
  };

  return (
    <div className="webhooks-container">
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Typography variant="subtitle1">Webhook URL</Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            value={webhookUrl}
            onChange={(e) => setWebhookUrl(e.target.value)}
          />
        </Grid>

        {Object.entries(webhookEvents.webhookEvents).map(
          ([category, events]) => (
            <Grid item xs={12} key={category}>
              <div style={{ display: "flex" }}>
                <Grid item xs={2}>
                  <Typography variant="subtitle1" gutterBottom>
                    {category}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <div
                    style={{
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      padding: "5px",
                      maxHeight: "150px",
                      overflowY: "auto",
                      textAlign: "left",
                      paddingLeft: "20%",
                      marginTop: "20px",
                    }}
                  >
                    {events.map((eventType) => (
                      <div key={eventType}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={webhookProperties[eventType] || false}
                              onChange={() => handleChange(eventType, category)}
                            />
                          }
                          label={eventType}
                        />
                      </div>
                    ))}
                  </div>
                </Grid>
              </div>
            </Grid>
          )
        )}
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <LoadingButton
            loading={isLoading}
            sx={{ marginTop: "10px" }}
            variant="contained"
            onClick={handleSave}
          >
            Save
          </LoadingButton>
        </Grid>
      </Grid>
    </div>
  );
}

export default Webhooks;
