const webhookEvents = {
  Payment: [
    "PAYMENT_INITIATED",
    "PAYMENT_PROCESSING",
    "PAYMENT_PENDING",
    "PAYMENT_COMPLETED",
    "PAYMENT_FAILED",
    "PAYMENT_CANCELLED",
    "PAYMENT_EXPIRED",
    "PAYMENT_ERROR",
    "PAYMENT_HELD",
    "PAYMENT_REVERSED",
  ],
  Capture: [
    "CAPTURE_INITIATED",
    "CAPTURE_PROCESSING",
    "CAPTURE_PENDING",
    "CAPTURE_COMPLETED",
    "CAPTURE_FAILED",
    "CAPTURE_CANCELLED",
    "CAPTURE_EXPIRED",
    "CAPTURE_ERROR",
    "CAPTURE_HELD",
    "CAPTURE_REVERSED",
  ],
  Refund: [
    "REFUND_INITIATED",
    "REFUND_PROCESSING",
    "REFUND_PENDING",
    "REFUND_COMPLETED",
    "REFUND_FAILED",
    "REFUND_CANCELLED",
    "REFUND_EXPIRED",
    "REFUND_ERROR",
    "REFUND_HELD",
    "REFUND_REVERSED",
  ],
  Credit: [
    "CREDIT_INITIATED",
    "CREDIT_PROCESSING",
    "CREDIT_PENDING",
    "CREDIT_COMPLETED",
    "CREDIT_FAILED",
    "CREDIT_CANCELLED",
    "CREDIT_EXPIRED",
    "CREDIT_ERROR",
    "CREDIT_HELD",
    "CREDIT_REVERSED",
  ],
  Customer: ["CUSTOMER_ACTIVE", "CUSTOMER_DISABLED", "CUSTOMER_HELD"],
  PaymentInstrument: [
    "PAYMENT_INSTRUMENT_ACTIVE",
    "PAYMENT_INSTRUMENT_DISABLED",
    "PAYMENT_INSTRUMENT_HELD",
  ],
};

export default { webhookEvents };
