import React from 'react';

const Radio = () => (<svg xmlns="https://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
<path d="M2 10.4114C2 5.9931 5.58172 2.41138 10 2.41138C14.4183 2.41138 18 5.9931 18 10.4114C18 14.8297 14.4183 18.4114 10 18.4114C5.58172 18.4114 2 14.8297 2 10.4114Z" fill="#E1E4EA"/>
<g filter="url(#filter0_d_6_35814)">
  <path d="M3.5 10.4114C3.5 6.82153 6.41015 3.91138 10 3.91138C13.5899 3.91138 16.5 6.82153 16.5 10.4114C16.5 14.0012 13.5899 16.9114 10 16.9114C6.41015 16.9114 3.5 14.0012 3.5 10.4114Z" fill="white"/>
</g>
<defs>
  <filter id="filter0_d_6_35814" x="1.5" y="3.91138" width="17" height="17" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="2"/>
    <feGaussianBlur stdDeviation="1"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.105882 0 0 0 0 0.109804 0 0 0 0 0.113725 0 0 0 0.12 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6_35814"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6_35814" result="shape"/>
  </filter>
</defs>
</svg>);

export default Radio;